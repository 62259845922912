import { FC, ReactNode } from 'react';
import { Person3Icon } from 'icons/person-3';
import { Avatar, AvatarProps } from '@mui/material';
import s from './avatar-circle.module.scss';

const getAbbreviation = (firstName: string, lastName: string) => {
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
};

type AvatarCircleProps = AvatarProps & {
  isEmpty: boolean;
  lastName?: string;
  image?: string | ReactNode;
  className?: string;
  firstName?: string;
  withHover?: boolean;
  imageSrc?: string | ReactNode;
};

export const AvatarCircle: FC<AvatarCircleProps> = ({
  isEmpty,
  lastName,
  imageSrc,
  firstName,
  className,
  withHover = true,
  ...rest
}) => {
  if (isEmpty) {
    return (
      <Avatar data-with-hover={withHover} className={`${s.avatar} ${className}`} {...rest}>
        <Person3Icon />
      </Avatar>
    );
  }

  const content =
    imageSrc && typeof imageSrc === 'string' ? (
      <Avatar data-with-hover={withHover} className={`${s.avatar} ${className}`} src={imageSrc} {...rest} />
    ) : (
      <Avatar data-with-hover={withHover} className={`${s.avatar} ${className}`} {...rest}>
        {imageSrc || getAbbreviation(firstName, lastName)}
      </Avatar>
    );

  return content;
};
