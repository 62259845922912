import { useEffect, FC } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { WarningIcon } from 'icons/warning';
import { IdName } from 'types/common-types';
import { useLocation } from 'react-router-dom';
import {
  MoveModalState,
  Actions as DepartmentsActions,
  Selectors as DepartmentsSelectors,
} from 'pages/departments/departments.controller';
import MoveModal from 'components/move-modal/move-modal';
import Skeleton from 'components/ui-new/skeleton/skeleton';
import Header from 'pages/departments/modules/header/header';
import EmptyState from 'pages/departments/components/empty-state';
import WidthContainer from 'components/width-container/width-container';
import SubHeader from 'pages/departments/modules/sub-header/sub-header';
import AddPositionModal from 'components/add-position-modal/add-position-modal';
import { Page } from 'components/add-position-modal/add-position-modal.controller';
import { Actions as MultiselectActions } from 'pages/products/multiselect.controller';
import DepartmentDataItems from 'pages/departments/modules/department-data-items/department-data-items';
import s from './departments.module.scss';

type StateProps = {
  isEmpty: boolean;
  isLoading: boolean;
  moveModal: MoveModalState;
};
type DispatchProps = {
  moveItems: () => void;
  closeMoveModal: () => void;
  disposeMultiselect: () => void;
  loadData: (departmentId: string) => void;
  selectParentDepartment: (value: IdName) => void;
};
type Props = StateProps & DispatchProps;

const skeleton = (
  <>
    <Skeleton variant="text" width="300px" height={46} style={{ marginBottom: 0, marginTop: '-9px' }} />
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
      <Skeleton variant="rounded" width="25%" height={41} />
      <div style={{ display: 'flex', gap: '8px' }}>
        <Skeleton variant="rounded" width="190px" height={41} />
        <Skeleton variant="rounded" width="160px" height={41} />
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
      <Skeleton variant="rounded" width="230px" height={41} />
      <Skeleton variant="rounded" width="78px" height={41} />
    </div>
    <Skeleton variant="rounded" width="100%" height={60} style={{ marginBottom: 8 }} />
    <Skeleton variant="rounded" width="100%" height={60} style={{ marginBottom: 8 }} />
    <Skeleton variant="rounded" width="100%" height={60} style={{ marginBottom: 8 }} />
    <Skeleton variant="rounded" width="100%" height={60} style={{ marginBottom: 8 }} />
    <Skeleton variant="rounded" width="100%" height={60} style={{ marginBottom: 8 }} />
    <Skeleton variant="rounded" width="100%" height={60} style={{ marginBottom: 8 }} />
    <Skeleton variant="rounded" width="100%" height={60} style={{ marginBottom: 8 }} />
  </>
);

const Departments: FC<Props> = ({
  isEmpty,
  moveModal,
  isLoading,
  loadData,
  moveItems,
  closeMoveModal,
  disposeMultiselect,
  selectParentDepartment,
}) => {
  const { pathname } = useLocation();
  const match = pathname.match(/\/([a-f\d-]+)$/i);
  const id = match ? match[1] : null;

  useEffect(() => {
    document.title = 'Departments';
    return () => {
      disposeMultiselect();
    };
  }, []);

  useEffect(() => {
    loadData(id);
  }, [id]);

  const warningBanner = (
    <div className={s.warning_banner}>
      <WarningIcon stroke="#DF8839" />
      <span>{`Relocation may affect the department's future operations.`}</span>
    </div>
  );

  return (
    <WidthContainer className={s.container}>
      {isLoading ? (
        skeleton
      ) : (
        <>
          <Header />

          {isEmpty ? (
            <EmptyState />
          ) : (
            <>
              <SubHeader />
              <DepartmentDataItems />
            </>
          )}
        </>
      )}
      <AddPositionModal page={Page.Departments} />
      <MoveModal
        dontClose
        label="department"
        moveButtonText="Move"
        warning={warningBanner}
        options={moveModal.options}
        isOpen={moveModal.isModalOpen}
        value={moveModal.selectedValue}
        placeholder="Choose department"
        subTitle="Choose other department"
        isProcessing={moveModal.isProcessing}
        isMoveButtonDisabled={!moveModal.selectedValue}
        handleMove={moveItems}
        closeModal={closeMoveModal}
        onChangeValue={selectParentDepartment}
      />
    </WidthContainer>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  isLoading: state.departments.isLoading,
  moveModal: state.departments.moveModal,
  isEmpty: DepartmentsSelectors.isEmpty(state),
});
const mapDispatchToProps: DispatchProps = {
  loadData: DepartmentsActions.loadData,
  moveItems: DepartmentsActions.moveItems,
  closeMoveModal: DepartmentsActions.closeMoveModal,
  disposeMultiselect: MultiselectActions.disposeState,
  selectParentDepartment: DepartmentsActions.selectParentDepartment,
};
export default connect(mapStateToProps, mapDispatchToProps)(Departments);
