export enum ProductConfigFileTypesEnum {
  txt = 'txt',
  doc = 'doc',
  docx = 'docx',
  pdf = 'pdf',
  jpg = 'jpg',
  jpeg = 'jpeg',
  png = 'png',
  mp4 = 'mp4',
  xls = 'xls',
  xlsx = 'xlsx',
  mov = 'mov',
  webp = 'webp',
}

export enum ProductMediaFileTypesEnum {
  png = 'png',
  jpg = 'jpg',
  jpeg = 'jpeg',
  webp = 'webp',
}

export enum CompanyLogoFileTypesEnum {
  svg = 'svg',
  jpg = 'jpg',
  png = 'png',
  jpeg = 'jpeg',
  webp = 'webp',
}
