import { FC, SVGProps } from 'react';

export const HandIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none" {...props}>
    <path
      d="M11.5183 5.897L11.2819 5.49246C11.185 5.33294 11.1212 5.1559 11.0943 4.97179C11.0674 4.78769 11.0779 4.60017 11.1251 4.4201C11.1724 4.24004 11.2554 4.07112 11.3695 3.92308C11.4835 3.77505 11.6262 3.65098 11.7893 3.55799C11.9534 3.46372 12.1345 3.40224 12.3227 3.37731C12.5108 3.35238 12.702 3.36443 12.8854 3.41266C13.0688 3.46089 13.2408 3.54442 13.3915 3.65844C13.5421 3.77245 13.6685 3.91469 13.7634 4.07707L17.0426 9.68812C17.7588 10.9234 17.9508 12.3887 17.5766 13.7634C17.2025 15.1382 16.2925 16.3106 15.0458 17.0245L14.7182 17.2114C12.871 18.259 10.6785 18.5395 8.62207 17.9914L7.45063 17.678C6.45256 17.4126 5.60185 16.7668 5.08536 15.8823L4.61251 15.0733C4.09692 14.1886 3.9562 13.1385 4.22105 12.1518L4.51598 11.0776"
      stroke="#3153D6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.99469 9.55548L5.75346 7.43164C5.66205 7.26902 5.53873 7.12594 5.39079 7.01087C5.24285 6.8958 5.07323 6.811 4.89177 6.76138C4.7103 6.71175 4.52068 6.69837 4.3339 6.7219C4.14712 6.74543 3.96698 6.80552 3.80392 6.89855C3.63755 6.9898 3.49143 7.11313 3.37432 7.26131C3.25721 7.40949 3.17141 7.57936 3.12216 7.76095C3.07291 7.94254 3.06114 8.13205 3.08757 8.31823C3.11399 8.5044 3.1781 8.68348 3.27601 8.84469L5.77023 13.1125"
      stroke="#3153D6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.49182 8.1309L7.65954 4.9957C7.56717 4.83133 7.44224 4.68695 7.29225 4.57125C7.14225 4.45556 6.97024 4.37086 6.78642 4.32221C6.60261 4.27355 6.41082 4.262 6.22236 4.28811C6.03391 4.31421 5.85271 4.37757 5.68953 4.47429C5.36589 4.66734 5.13157 4.97784 5.03674 5.33939C4.94191 5.70094 4.99406 6.08473 5.18208 6.40875L7.00733 9.53191"
      stroke="#3153D6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.9908 6.70588L10.7496 4.58203C10.6572 4.41766 10.5323 4.27327 10.3823 4.15758C10.2323 4.04189 10.0602 3.95721 9.87641 3.90855C9.6926 3.85989 9.50081 3.84835 9.31235 3.87445C9.1239 3.90056 8.94273 3.9639 8.77955 4.06062C8.61647 4.1536 8.47372 4.27768 8.3597 4.42571C8.24569 4.57374 8.1626 4.74266 8.11537 4.92273C8.06815 5.10279 8.05765 5.29032 8.08456 5.47443C8.11146 5.65853 8.17525 5.83556 8.2721 5.99508L9.51332 8.11893"
      stroke="#3153D6"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
