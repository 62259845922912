import { Envs } from 'env-cookies';
import { Tenant } from 'services/tenant.model';

export function getNonAuthorizedEnvHostname() {
  const ENV = process.env.REACT_APP_ENV_NAME as Envs;

  if (ENV === 'local') return 'local.domain.localhost';
  if (ENV === 'development') return 'dev.hesh.app';
  if (ENV === 'stage') return 'stage.hesh.app';
  if (ENV === 'production') return 'hesh.app';
  // TODO: Forbid to create dev.hesh.app and stage.hesh.app companies on production

  throw new Error('Unknown Environment');
}

export const getSubdomainFromUrl = (url: string) => {
  const nonAuthorizedHostName = getNonAuthorizedEnvHostname();
  const urlObject = new URL(url);
  const currentHostname = urlObject.hostname;

  return currentHostname.replace(`.${nonAuthorizedHostName}`, '');
};

export function getDesiredTenantByUrl(url: string, userTenants: Tenant[]): Tenant {
  const subdomainFromUrl = getSubdomainFromUrl(url);

  const desiredTenant = userTenants?.find((item) => item.subdomain === subdomainFromUrl);
  return desiredTenant;
}

export function getTenantHostname(tenantId: string, userTenants: Tenant[]): string {
  const tenant = userTenants.find((item) => item.id === tenantId);
  return `${tenant.subdomain}.${getNonAuthorizedEnvHostname()}`;
}
