/* eslint-disable jsx-a11y/no-autofocus */
import { Tooltip } from '@mui/material';
import { useClickAway, useKeyPress } from 'ahooks';
import InputButtons from 'components/input-buttons/input-buttons';
import { LightTooltip } from 'components/ui-new/light-tooltip/light-tooltip';
import { CSSProperties, HTMLAttributes, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import s from './text-editable.module.scss';

const hiddenStyles: CSSProperties = { opacity: 0, position: 'absolute', pointerEvents: 'none' };
const visibleStyles = (position: 'relative' | 'absolute' = 'relative'): CSSProperties => ({
  opacity: 1,
  position,
  pointerEvents: 'all',
});

export type TextEditableProps = HTMLAttributes<HTMLDivElement> & {
  arrayOfUniqueValues?: { value: string; id: number | string }[]; // an array by the values of which the current value (inputValue) is compared
  idOfCurrentValue?: number | string; // if the array of values also contains our value
  tooltipTextForNonUniqueValue?: string; // custom text for the tooltip if the value is not unique
  value: string;
  tooltip?: string;
  highlitedValue?: string;
  maxWidth?: string;
  focused?: boolean;
  className?: string;
  placeholder?: string;
  withLoader?: boolean;
  style?: CSSProperties;
  errorTitle?: ReactNode;
  textValidationTitle?: ReactNode;
  maxLettersWarning?: number;
  textClassName?: string;
  allowedEmpty?: boolean;
  resizableInput?: boolean;
  disableActions?: boolean;
  isNotClickable?: boolean;
  enableClickAway?: boolean;
  inputWidth?: number | string;
  placeholderClassName?: string;
  previewWidth?: number | string;
  inputMinWidth?: number | string;
  previewMinWidth?: number | string;
  actionsPosition?: 'bottom' | 'right' | 'top';
  onCancelClick?: () => void;
  onClickAway?: () => void;
  onCheckClick?: () => void;
  onEditModeStart?: () => void;
  onValueChange?: (value: string) => void;
  onEditModeEnd?: (value: string) => void;
  validationCallback?: (value: string) => Promise<boolean>;
  onEditEnd?: ((value: string) => void) | ((value: string) => Promise<void>);
  validateValue?: ((value: string) => boolean) | ((value: string) => Promise<boolean>);
};

export default function TextEditable({
  arrayOfUniqueValues,
  idOfCurrentValue,
  tooltipTextForNonUniqueValue = 'Така назва вже існує',
  className,
  style = {},
  tooltip,
  textClassName,
  errorTitle = '',
  textValidationTitle = '',
  maxLettersWarning = 256,
  focused = false,
  placeholder = '',
  maxWidth = '100%',
  withLoader = false,
  inputWidth = 'auto',
  allowedEmpty = false,
  placeholderClassName,
  previewWidth = 'auto',
  resizableInput = false,
  inputMinWidth = '100%',
  isNotClickable = false,
  disableActions = false,
  enableClickAway = true,
  value: initialValue = '',
  highlitedValue = '',
  previewMinWidth = '100%',
  actionsPosition = 'bottom',
  onEditEnd,
  onClickAway,
  onCheckClick,
  onCancelClick,
  onEditModeEnd,
  onEditModeStart,
  validationCallback,
  onValueChange = () => {},
  validateValue = () => true,
  ...rest
}: TextEditableProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [isUniqueValue, setIsUniqueValue] = useState(true);
  const [isInputValueValid, setIsInputValueValid] = useState(false);
  const [isShowValueTooltip, setIsShowValueTooltip] = useState(false);
  const [inputValue, setInputValue] = useState(initialValue);
  const [isEditMode, setIsEditMode] = useState(focused || false);
  const [resizableInputWidth, setResizableInputWidth] = useState(0);

  const isButtonDisabled = isShowError || isInputValueValid || !isUniqueValue || (!allowedEmpty && inputValue.trim() === '');
  const valueTooltip = tooltip || (isShowValueTooltip && inputValue) || '';

  const resetState = () => {
    if (isShowError) setIsShowError(false);

    setIsEditMode(false);
    if (allowedEmpty || initialValue.trim() !== '') {
      setInputValue(initialValue);
    }
    setIsInputValueValid(false);
  };

  const isInputValueLengthValid = (value) => {
    return value.length < maxLettersWarning;
  };

  const updateValue = async () => {
    if (!isEditMode) return;
    if (!isUniqueValue) return;

    await setInputValue(inputValue.trim());

    if (inputValue === initialValue) {
      if (onCheckClick) onCheckClick();

      setIsEditMode(false);
      return;
    }

    try {
      if (withLoader) setIsLoading(true);

      const isLengthValid = isInputValueLengthValid(inputValue);

      if (!isLengthValid) {
        setIsInputValueValid(true);
        return;
      }

      const isAllowUpdate = inputValue.trim() === '' || (!isShowError && (await validateValue(inputValue)));

      if (!isAllowUpdate) {
        setIsShowError(true);
        return;
      }

      if (validationCallback) {
        const data = await validationCallback(inputValue);
        if (!data) return;
      }

      if (allowedEmpty || inputValue.trim() !== '') {
        if (onEditEnd) await onEditEnd(inputValue.trim());
        setIsEditMode(false);
      }
    } finally {
      if (withLoader) setIsLoading(false);
    }
  };

  const onClickCapture = (event) => {
    if (isNotClickable) return;

    event.stopPropagation();
    setIsEditMode(true);
    inputRef.current.select();
  };

  const actionsTransform = useMemo(() => {
    if (actionsPosition === 'bottom') return `translate(0, 110%)`;

    if (actionsPosition === 'top') return `translate(0, -110%)`;

    if (actionsPosition === 'right') return `translate(110%, 0)`;

    return `translate(0, 110%)`;
  }, [actionsPosition]);

  useKeyPress(['enter'], () => {
    if (isEditMode) updateValue();
  });

  useKeyPress(['esc', 'escape'], () => {
    if (onClickAway && isEditMode) onClickAway();
    resetState();
  });

  const handleOnCancelClick = () => {
    resetState();
    if (isEditMode && onCancelClick) {
      onCancelClick();
    }
  };

  // possibility to switch into edit mode from outer component
  useEffect(() => {
    if (focused) {
      inputRef.current.select();
    }

    if (focused && !isEditMode) {
      setIsEditMode(true);
      inputRef.current.select();
    }
    if (!focused && isEditMode) {
      setIsEditMode(false);
      setInputValue(initialValue);
    }
  }, [focused]);

  // possibility to notify outer component about state change
  useEffect(() => {
    if (isEditMode && onEditModeStart) onEditModeStart();

    if (!isEditMode && onEditModeEnd) onEditModeEnd(inputValue);
  }, [isEditMode]);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (divRef?.current?.scrollWidth > divRef?.current?.clientWidth) {
      setIsShowValueTooltip(true);
    } else {
      setIsShowValueTooltip(false);
    }
  }, [divRef?.current?.scrollWidth, divRef?.current?.clientWidth]);

  useClickAway(() => {
    if (!enableClickAway) return;
    if (onClickAway && isEditMode) onClickAway();

    resetState();
  }, [containerRef, inputRef, divRef]);

  useEffect(() => {
    setResizableInputWidth(divRef.current.clientWidth);

    if (isEditMode) {
      if (arrayOfUniqueValues && !idOfCurrentValue) {
        setIsUniqueValue(!arrayOfUniqueValues.some((i) => i.value.trim() === inputValue.trim()));
      }

      if (arrayOfUniqueValues && idOfCurrentValue) {
        const isUnique = arrayOfUniqueValues.every((i) => i.value.trim() !== inputValue.trim() || i.id === idOfCurrentValue);
        setIsUniqueValue(isUnique);
      }
    }
  }, [inputValue, isEditMode]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef?.current && !containerRef?.current.contains(event.target)) {
        setInputValue(initialValue);
        setIsEditMode(false);
        onClickAway?.();
      }
    };

    if (isEditMode) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditMode, setIsEditMode, onClickAway]);

  return (
    <LightTooltip
      title={isInputValueValid ? textValidationTitle : errorTitle}
      open={isShowError || isInputValueValid}
      disableHoverListener
      disableTouchListener
      className={s.tooltip}
      placement="top-start"
    >
      <div
        className={`${s.container} ${className}`}
        style={{ width: isEditMode ? inputWidth : previewWidth, ...style }}
        ref={containerRef}
        {...rest}
      >
        <Tooltip title={valueTooltip} placement="top-start">
          <div
            style={{
              ...(isEditMode || (!inputValue && placeholder) ? hiddenStyles : visibleStyles()),
              width: !resizableInput && previewWidth,
              maxWidth,
              minWidth: resizableInput && initialValue ? 'auto' : previewMinWidth,
            }}
            className={`${isEditMode ? s.hidden_preview : s.preview} ${textClassName} ${isNotClickable ? s.not_clickable : ''}`}
            ref={divRef}
            onClickCapture={(event) => onClickCapture(event)}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: highlitedValue || inputValue,
            }}
          />
        </Tooltip>
        <div
          style={{ ...(!inputValue && placeholder && !isEditMode ? visibleStyles() : hiddenStyles) }}
          className={`${s.placeholder} ${placeholderClassName} ${isNotClickable ? s.not_clickable : ''}`}
          onClickCapture={(event) => onClickCapture(event)}
        >
          {placeholder}
        </div>
        <Tooltip
          placement="top-start"
          open={!isUniqueValue && isEditMode && true}
          title={isUniqueValue ? '' : tooltipTextForNonUniqueValue}
        >
          <input
            style={{
              ...(isEditMode ? visibleStyles() : hiddenStyles),
              minWidth: inputMinWidth,
              width: resizableInput ? resizableInputWidth : inputWidth,
            }}
            className={`${s.input} ${textClassName}`}
            ref={inputRef}
            value={inputValue || ''}
            onClick={(e) => e.stopPropagation()}
            onChange={(event) => {
              // ignore changes while not in edit mode (for example: when user left edit mode and pressed CMD+Z action)
              if (isEditMode) {
                if (isShowError) setIsShowError(false);
                if (!isInputValueLengthValid(event.currentTarget.value)) {
                  if (event.currentTarget.value.length <= maxLettersWarning) setInputValue(event.currentTarget.value);
                  setIsInputValueValid(true);
                  return;
                }
                setIsInputValueValid(false);

                setInputValue(event.currentTarget.value);
                // notify outer component about changes (required in very specific cases)
                onValueChange(event.currentTarget.value);
              }
            }}
          />
        </Tooltip>

        {!disableActions && (
          <div
            className={s.actions}
            style={{ ...(isEditMode ? visibleStyles('absolute') : hiddenStyles), transform: actionsTransform }}
          >
            <InputButtons
              isLoading={isLoading}
              onConfirmClick={updateValue}
              onCancelClick={handleOnCancelClick}
              isConfirmDisabled={isButtonDisabled}
            />
          </div>
        )}
      </div>
    </LightTooltip>
  );
}
