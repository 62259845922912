import { AppState } from 'redux/store';
import { IdName } from 'types/common-types';
import { Selectors as AuthSelectors } from 'redux/auth.controller';
import { StateController } from '../../state-controller';
import { AccessLevel, Permission } from '../../services/permission.model';

export type ModalsState = {
  isOpen: boolean;
};

const defaultState: ModalsState = {
  isOpen: false,
};

const stateController = new StateController<ModalsState>('INSUFFICIENT_RIGHTS_CONTROLLER', defaultState);

export class PermissionGuardActions {
  public static checkPermissionAndShowModal(permission: Permission, values: AccessLevel[], taskDepartments?: IdName[]) {
    return (dispatch, getState) => {
      let isPermitted: boolean;
      if (taskDepartments) {
        isPermitted = PermissionGuardSelectors.checkEditLevel(getState(), permission, values, taskDepartments);
      } else {
        isPermitted = PermissionGuardSelectors.selectIsPermitted(getState(), permission, values);
      }

      if (!isPermitted) {
        dispatch(PermissionGuardActions.openModal());
      }
      return isPermitted;
    };
  }

  public static openModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: true }));
    };
  }

  public static closeModal() {
    return (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
    };
  }
}

export class PermissionGuardSelectors {
  public static selectIsPermitted(state: AppState, permission: Permission, values: AccessLevel[]) {
    const userPermissions = state.auth.user?.user_permissions;

    if (!userPermissions) return null;

    const isAdmin = userPermissions[Permission.webAdmin] === AccessLevel.access;
    const isPermitted = isAdmin || values.some((item) => item === userPermissions[permission]);
    return isPermitted;
  }

  public static checkEditLevel(
    state: AppState,
    permission: Permission,
    accessLevels: AccessLevel[],
    taskDepartments: IdName[] = [],
  ) {
    const userPermissions = state.auth.user?.user_permissions;
    const userDepartmentIds = AuthSelectors.getUserDepartmentIds(state);
    const childDepartmentIds = state.auth.user.childDepartments.map((i) => i.id);

    const isPermitted = accessLevels.some((item) => item === userPermissions[permission]);
    const isAdmin = userPermissions[Permission.webAdmin] === AccessLevel.access;

    const inUserDepartment = taskDepartments.some((el) => userDepartmentIds.includes(el.id));
    const inSubDepartments = taskDepartments.some((el) => childDepartmentIds.includes(el.id));

    const permittedForAllDepartments = userPermissions[permission] === AccessLevel.allDepartments && isPermitted;
    const permittedForUserDepartment =
      ((userPermissions[permission] === AccessLevel.userDepartment && inUserDepartment) || taskDepartments.length === 0) &&
      isPermitted;
    const permittedForSubDepartments =
      ((userPermissions[permission] === AccessLevel.userDepartmentSubDepartments && (inUserDepartment || inSubDepartments)) ||
        taskDepartments.length === 0) &&
      isPermitted;
    const isAccess = userPermissions[permission] === AccessLevel.access && isPermitted;

    if (permittedForUserDepartment || permittedForSubDepartments || permittedForAllDepartments || isAccess || isAdmin) {
      return true;
    }
    return false;
  }

  public static isAllTasksPermittedToEdit(state: AppState, permission: Permission, taskDepartments: IdName[][] = []) {
    const userPermissions = state.auth.user?.user_permissions;
    const userDepartmentIds = AuthSelectors.getUserDepartmentIds(state);
    const childDepartmentIds = state.auth.user.childDepartments.map((i) => i.id);

    const checkArray = (userArray: string[], target: IdName[] = []) => {
      if (target.length === 0) return true;
      return target.every((el) => userArray.includes(el.id));
    };

    const inUserDepartment = taskDepartments.map((i) => checkArray(userDepartmentIds, i)).every((e) => e);
    const inSubDepartments = taskDepartments.map((i) => checkArray(childDepartmentIds, i)).every((e) => e);

    const isAdmin = userPermissions[Permission.webAdmin] === AccessLevel.access;
    const permittedForAllDepartments = userPermissions[permission] === AccessLevel.allDepartments;
    const permittedForUserDepartment = userPermissions[permission] === AccessLevel.userDepartment && inUserDepartment;
    const permittedForSubDepartments =
      userPermissions[permission] === AccessLevel.userDepartmentSubDepartments && inSubDepartments;

    if (permittedForUserDepartment || permittedForSubDepartments || permittedForAllDepartments || isAdmin) {
      return true;
    }
    return false;
  }
}

export const reducer = stateController.getReducer();
