import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import MoveToIcon from 'icons/move-to';
import { AppState } from 'redux/store';
import { TrashIcon } from 'icons/trash';
import { Tooltip } from '@mui/material';
import { PageName } from 'types/common-enums';
import Button from 'components/button/button';
import { useLocation } from 'react-router-dom';
import Checkbox from 'components/checkbox/checkbox';
import QuickSearch from 'pages/departments/modules/sub-header/components/quick-search/quick-search';
import { Actions as MultiselectActions, Selectors as MultiselectSelectors } from 'pages/products/multiselect.controller';
import {
  Actions as DepartmentsActions,
  Selectors as DepartmentsSelectors,
  QuickSearchState,
} from 'pages/departments/departments.controller';
import VerticalShiftAnimation from 'components/wrapper-with-shift-animation/vertical-shift-animation';
import HorizontalShiftAnimation from 'components/wrapper-with-shift-animation/horisontal-shift-animation';
import s from './sub-header.module.scss';

type StateProps = {
  allItemsCount: number;
  isMultiselect: boolean;
  selectedItemsCount: number;
  quickSearch: QuickSearchState;
};
type DispatchProps = {
  deselectAll: () => void;
  clearItemList: () => void;
  openMoveModal: () => void;
  selectAll: (page: PageName) => void;
  openDeleteConfirmationModal: () => void;
  getItemsByKeyword: (keyword: string) => void;
  changeMultiselectMode: (value: boolean) => void;
};
type Props = StateProps & DispatchProps;

const SubHeader: FC<Props> = ({
  quickSearch,
  isMultiselect,
  allItemsCount,
  selectedItemsCount,
  selectAll,
  deselectAll,
  openMoveModal,
  clearItemList,
  getItemsByKeyword,
  changeMultiselectMode,
  openDeleteConfirmationModal,
}) => {
  const location = useLocation();
  const isSelectedAll = selectedItemsCount === allItemsCount;

  const handleSelect = () => {
    if (isSelectedAll) {
      deselectAll();
    } else {
      selectAll(PageName.Departments);
    }
  };

  useEffect(() => {
    if (selectedItemsCount) {
      deselectAll();
    }
  }, [location]);

  const buttonText = selectedItemsCount > 0 ? `Select - ${selectedItemsCount}` : 'Select';

  return (
    <div className={s.container}>
      <div className={s.row}>
        <QuickSearch
          items={quickSearch.items}
          clearItemList={clearItemList}
          isFetching={quickSearch.isFetching}
          getItemsByKeyword={getItemsByKeyword}
        />

        <div className={s.row}>
          <Button size="M" color="secondary" variant="contained" onClick={() => changeMultiselectMode(!isMultiselect)}>
            {buttonText}
          </Button>
          <HorizontalShiftAnimation
            isVisible={selectedItemsCount > 0}
            maxWidthWhenIsShown={98}
            animationTime={0.28}
            componentClassName={s.buttons_wrapper}
          >
            <div className={s.buttons}>
              <Tooltip disableHoverListener={!isMultiselect} title="Move to" placement="top-start">
                <Button
                  size="L"
                  type="button"
                  color="black"
                  shape="square"
                  variant="outlined"
                  onClick={() => openMoveModal()}
                  className={s.button}
                >
                  <MoveToIcon fill="#878B92" height={16} width={16} />
                </Button>
              </Tooltip>
              <Tooltip disableHoverListener={!isMultiselect} title="Delete" placement="top-end">
                <Button
                  size="L"
                  type="button"
                  color="black"
                  shape="square"
                  variant="outlined"
                  onClick={() => openDeleteConfirmationModal()}
                  className={s.button}
                >
                  <TrashIcon height={16} width={16} />
                </Button>
              </Tooltip>
            </div>
          </HorizontalShiftAnimation>
        </div>
      </div>

      <VerticalShiftAnimation isVisible={isMultiselect} maxHeightWhenIsShown={37}>
        <div className={s.select_all}>
          <Checkbox size="medium" checked={isSelectedAll} onClick={handleSelect} />
          Select
        </div>
      </VerticalShiftAnimation>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  quickSearch: state.departments.quickSearch,
  isMultiselect: state.products.multiselect.isMultiselect,
  allItemsCount: DepartmentsSelectors.allItemsCount(state),
  selectedItemsCount: MultiselectSelectors.selectedDepartmentsAndPositionsCount(state),
});
const mapDispatchToProps: DispatchProps = {
  selectAll: MultiselectActions.selectAll,
  deselectAll: MultiselectActions.deselectAll,
  clearItemList: DepartmentsActions.clearItemList,
  openMoveModal: DepartmentsActions.openMoveModal,
  getItemsByKeyword: DepartmentsActions.getItemsByKeyword,
  changeMultiselectMode: MultiselectActions.changeMultiselectMode,
  openDeleteConfirmationModal: DepartmentsActions.openDeleteConfirmationModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);
