import { IMAGE_FILE_MAX_SIZE, TEXT_FILE_MAX_SIZE, VIDEO_FILE_MAX_SIZE } from 'constants/file-max-size';
import { TEXT_FILES_TYPES, VIDEOS_FILES_TYPES, IMAGES_FILES_TYPES } from 'constants/file-types';
import { ProductMediaActions } from 'pages/product-flow/pages/product/controllers/product-media.controller';
import { notify } from 'notifications';
import { Dispatch } from 'redux';
import s from './upload-file-type-validation.module.scss';

export const checkImageResolution = (file: File) =>
  new Promise<{ width: number; height: number }>((resolve) => {
    const image = new Image();

    image.onload = () => {
      const { width, height } = image;
      resolve({ width, height });
    };

    image.src = URL.createObjectURL(file);
  });

export const uploadingFileTypeValidation = async (
  files: File[],
  allowedTypes: { [key: string]: string },
  dispatch: Dispatch<any>,
): Promise<File[]> => {
  const filter = async (item) => {
    const type = item.name.split('.').pop()?.toLowerCase();
    const fileSize = item.size;

    if (!allowedTypes[type]) {
      notify.error(`File ${item.name} has invalid file type!`);
      return false;
    }

    if (TEXT_FILES_TYPES.includes(type) && fileSize > TEXT_FILE_MAX_SIZE[type]) {
      notify.error(
        <>
          <p>Upload error.</p>
          <p className={s.title}>{item.name} file size limit exceeded!</p>
          <span className={s.learn_more} onClick={() => dispatch(ProductMediaActions.openFileSizeLimitModal())}>
            Learn more
          </span>
        </>,
      );
      return false;
    }

    if (VIDEOS_FILES_TYPES.includes(type) && fileSize > VIDEO_FILE_MAX_SIZE[type]) {
      notify.error(
        <>
          <p>Upload error.</p>
          <p className={s.title}>{item.name} file size limit exceeded!</p>
          <span className={s.learn_more} onClick={() => dispatch(ProductMediaActions.openFileSizeLimitModal())}>
            Learn more
          </span>
        </>,
      );
      return false;
    }

    if (IMAGES_FILES_TYPES.includes(type)) {
      const isOversize = fileSize > IMAGE_FILE_MAX_SIZE[type];

      if (isOversize) {
        notify.error(
          <>
            <p>Upload error.</p>
            <p className={s.title}>{item.name} file size limit exceeded!</p>
            <span className={s.learn_more} onClick={() => dispatch(ProductMediaActions.openFileSizeLimitModal())}>
              Learn more
            </span>
          </>,
        );
        return false;
      }
    }

    return item;
  };

  const mappedResult = await Promise.all(files.map(filter));

  return mappedResult.filter((item) => item);
};
