import {
  ManageOrderT,
  TaskInfoModel,
  UpdateTaskBody,
  CreateTaskBody,
  ManageFailedTasksBody,
  CreateUpdateTaskResponse,
  UpdateTaskRewardsBody,
  TaskRewardsModel,
  FailReasonsModel,
} from 'services/production-task.model';
import { IdName } from 'types/common-types';
import { PaginateResponse } from 'types/paginate-response';
import { baseAxiosInstance } from '../axios-config';

export class ProductionTaskService {
  static async getTaskInfo(taskId: string) {
    const { data } = await baseAxiosInstance.get<TaskInfoModel>(`/tasks/info/${taskId}`);
    return data;
  }

  static async getAllTasksKeys(search?: string, skip?: number, take?: number) {
    const { data } = await baseAxiosInstance.get<PaginateResponse<IdName>>(`/tasks/task-keys`, {
      params: {
        search,
        skip,
        take,
      },
    });
    return data;
  }

  static async createTask(body: CreateTaskBody) {
    const { data } = await baseAxiosInstance.post<CreateUpdateTaskResponse>(`/tasks/create`, body);
    return data;
  }

  static async updateTask(taskId: string, body: UpdateTaskBody) {
    const { data } = await baseAxiosInstance.put<CreateUpdateTaskResponse>(`/tasks/${taskId}`, body);
    return data;
  }

  static async updateTaskRewards(taskId: string, body: UpdateTaskRewardsBody) {
    const { data } = await baseAxiosInstance.put<TaskRewardsModel>(`/task-rewards/details/${taskId}`, body);

    return data;
  }

  static async manageTaskOrder(body: ManageOrderT) {
    const { data } = await baseAxiosInstance.post<CreateUpdateTaskResponse>(`/tasks/manage-order`, body);
    return data;
  }

  static async manageFailedTask(body: ManageFailedTasksBody) {
    const { data } = await baseAxiosInstance.post<{ message: string }>(`/tasks/manage-failed-tasks`, body);
    return data;
  }

  static async getFailReasons() {
    const { data } = await baseAxiosInstance.get<FailReasonsModel[]>(`/fail-reason/all`);
    return data;
  }

  static async deleteTask(id: string) {
    const { data } = await baseAxiosInstance.delete<CreateUpdateTaskResponse>(`/tasks/${id}`);
    return data;
  }
}
