import { Card } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { MODALS } from 'modules/root-modals/modals';
import React, { useState } from 'react';
import { useLeaveRoute } from 'hooks/use-leave-route';
import ModalBase, { Sizes } from 'components/ui-new/modal-base/modal-base';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/button';
import { AppState } from 'redux/store';
import { MAX_REASONS_CHARS_COUNT } from 'modules/manage-failed-tasks-modal/manage-failed-tasks.controller';
import s from './confirmation-modal.module.scss';

export type DeleteConfirmationOwnProps = {
  text: string | React.ReactNode;
  title?: string | React.ReactNode;
  icon?: string | React.ReactNode;
  cancelAction?: () => void;
  actionText?: string | React.ReactNode;
  actionButtonColor?: 'error' | 'primary';
  withCloseButton?: boolean;
  backgroundColor?: string;
  action?: () => Promise<void> | void;
  // isCenterModeWithoutTitle?: boolean;
  paddingSize?: Sizes;
  showFooter?: boolean;
  onClose?: () => void;
  cancelButton?: boolean;
  cancelButtonText?: string;
};

export default function ConfirmationModal({
  text,
  title,
  cancelAction,
  actionText,
  cancelButtonText = '',
  withCloseButton,
  icon,
  showFooter = true,
  backgroundColor = '#FFCECE',
  paddingSize = 'small',
  actionButtonColor = 'error',
  action,
  onClose = () => {},
  cancelButton = true,
}: DeleteConfirmationOwnProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isProcessing, setIsProcessing] = useState(false);

  const reasonForReopening = useSelector((state: AppState) => state.manage_failed_tasks.reasonForReopening);
  const isDeadlineWarning = useSelector((state: AppState) => state.production_workflow.isDeadlineWarningActive);

  const isDisabledActionButton = reasonForReopening.length > MAX_REASONS_CHARS_COUNT || isDeadlineWarning;

  useLeaveRoute(() => {
    closeModalAndCancelAction();
  });

  const closeModalAndCancelAction = () => {
    dispatch(ModalActions.closeModal(MODALS.CONFIRM));
    onClose();
    if (cancelAction) cancelAction();
  };

  const handleActionClick = async () => {
    try {
      setIsProcessing(true);
      await action();
      dispatch(ModalActions.closeModal(MODALS.CONFIRM));
    } finally {
      setIsProcessing(false);
    }
  };

  const renderFooter = () => {
    return (
      <>
        {cancelButton && (
          <Button variant="contained" color="secondary" size="XL" fullWidth onClick={closeModalAndCancelAction}>
            {cancelButtonText || t('global.button_cancel')}
          </Button>
        )}
        {actionText && (
          <Button
            size="XL"
            variant="contained"
            color={actionButtonColor}
            fullWidth
            loading={isProcessing}
            disabled={isDisabledActionButton}
            onClick={handleActionClick}
          >
            {actionText}
          </Button>
        )}
      </>
    );
  };

  return (
    <Card className={s.container}>
      <ModalBase
        title={title}
        icon={icon}
        bodyClassName={showFooter ? s.body : `${s.body} ${s.padding_bottom}`}
        footerClassName={s.footer}
        onClose={closeModalAndCancelAction}
        paddingSize={paddingSize}
        witohutShadow
        backgroundColor={backgroundColor}
        withCloseButton={withCloseButton}
        footer={showFooter && renderFooter()}
      >
        {text}
      </ModalBase>
    </Card>
  );
}
