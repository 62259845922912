export const rules = {
  isNotEmpty: (value: string | number | object) => {
    if (typeof value === 'string') return Boolean(value);
    if (typeof value === 'number') return !Number.isNaN(value);
    if (typeof value === 'object') return value !== null;
    return false;
  },
  isEmail: (value: string) => {
    // source https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
    const regExp =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!value.match(regExp);
  },
  isUniqueString: ({ value, valueArray }: { value: string; valueArray: string[] }) => {
    return !valueArray.includes(value);
  },
  isLengthLessOrEqualTo: ({ value, maxLength }: { value: string; maxLength: number }) => value.length <= maxLength,
  isNegative: (value: number | string) => Number(value) < 0,
  isPositive: (value: number | string) => Number(value) >= 0,
  isGreaterThanZero: (value: number | string) => Number(value) > 0,
  isValidPassword: (password: string) => {
    const validatedConstraints = {
      length: password.length >= 8,
      specialChar: /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password),
      uppercaseLowercase: /[A-Z]/.test(password) && /[a-z]/.test(password),
      number: /[0-9]/.test(password),
    };

    return validatedConstraints;
  },
} as const;

type RulesKeys = keyof typeof rules;
type Validators = { [key in RulesKeys]: (value: Parameters<(typeof rules)[key]>[0], errorMessage: string) => [boolean, string] };

function generateValidators(): Validators {
  const validators = {} as Validators;
  Object.keys(rules).forEach((rule) => {
    validators[rule] = (value, message) => {
      const isValid = rules[rule](value);
      const errorMessage = isValid ? null : message;
      return [isValid, errorMessage];
    };
  });
  return validators;
}

export const validators = generateValidators();

type Condition<TRuleFunc = (typeof rules)[RulesKeys]> = {
  rule: TRuleFunc;
  message: string;
};

export function createValidator(conditions: Condition[]) {
  return (value: any | { value: any; [key: string]: any }): [boolean, string[]] => {
    const messages = [];
    let isAllConditionsValid = true;
    conditions.forEach((element) => {
      const isValid = element.rule(value);
      if (!isValid) messages.push(element.message);
    });
    if (messages.length > 0) isAllConditionsValid = false;
    return [isAllConditionsValid, messages];
  };
}
