import { IdName } from 'types/common-types';

export enum AssignmentType {
  Manual = 'Manual',
  Auto = 'Auto',
  Self_Assignment = 'Self_Assignment',
}

export type ResponsibilityData = {
  name: string;
  task_template_id: string;
  reward_id: string;
  assigment_type: AssignmentType;
  responsibility_count: number;
  departments: string[];
  positionTypes: string[];
  users: string[];
  workflowTaskTemplateDepartmentRelations?: {
    id: string;
    workflow_task_template_responsibility_id: string;
    department_id: string;
    department: IdName;
  }[];
  workflowTaskTemplatePositionTypeRelations?: {
    id: string;
    workflow_task_template_responsibility_id: string;
    position_type_id: string;
    positionType: IdName;
  }[];
  workflowTaskTemplateUserRelations?: {
    id: string;
    workflow_task_template_responsibility_id: string;
    user_id: string;
    user: {
      id: string;
      first_name: string;
      last_name: string;
      avatar_image_url: string;
    };
  }[];
};

export type UpdateDeleteResponsibilityResponse = {
  id: string;
  name: string;
  task_template_id: string;
  reward_id: string;
  assigment_type: AssignmentType;
  responsibility_count: number;
};
