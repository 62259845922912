import { useRef } from 'react';
import ErrorIcon from 'icons/error';
import { notify } from 'notifications';
import { useDispatch } from 'react-redux';
import { DownloadIcon } from 'icons/download';
import Spinner from 'components/spinner/spinner';
import { ProductConfigFileTypesEnum } from 'types/file-enums';
import { uploadingFileTypeValidation } from 'utils/upload-file-type-validation/upload-file-type-validation';
import s from './file-upload.module.scss';

export type OwnProps = {
  style?: any;
  id?: string;
  sm?: boolean;
  disable?: boolean;
  isLoading?: boolean;
  withSpinner?: boolean;
  uploadingLimit?: number;
  fileCountLimit?: number;
  uploadedFileCount?: number;
  limitTitle?: string;
  acceptType?: string;
  isMultiple?: boolean;
  disabledByLimit?: boolean;
  onChange: (e: any) => void;
  fileTypes?: { [key: string]: string };
};

const preventEvent = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

// UNIQUE ID (IMPORTANT);
export default function FileUpload({
  id,
  sm,
  style,
  disable,
  isLoading,
  withSpinner,
  uploadingLimit = 10,
  fileCountLimit,
  uploadedFileCount,
  limitTitle = '',
  acceptType = '*',
  isMultiple = false,
  disabledByLimit = false,
  fileTypes = ProductConfigFileTypesEnum,
  onChange,
}: OwnProps) {
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const uploadFiles = async (files: File[]) => {
    if (files.length > uploadingLimit) {
      notify.error(`Upload error. It is not possible to upload more than 10 files at once`);
      return;
    }

    let filesToUpload = files;

    if (fileCountLimit && uploadedFileCount) {
      const remainingUploadSlots = fileCountLimit - uploadedFileCount;
      filesToUpload = files.slice(0, remainingUploadSlots);
    }

    if (files.length > filesToUpload.length) {
      notify.error('Upload failed. The maximum number of files (250) has been exceeded.');
    }

    onChange(await uploadingFileTypeValidation(filesToUpload, fileTypes, dispatch));
    ref.current.value = null;
  };

  const dropHandler = async (event) => {
    preventEvent(event);

    if (disabledByLimit || disable) return;

    const files = [];

    if (event.dataTransfer.items) {
      [...event.dataTransfer.items].forEach((item) => {
        if (item.kind === 'file') {
          files.push(item.getAsFile());
        }
      });
    } else {
      [...event.dataTransfer.files].forEach((file) => {
        files.push(file);
      });
    }

    uploadFiles(await uploadingFileTypeValidation(files, fileTypes, dispatch));
  };

  const handleChange = (event) => {
    event.preventDefault();
    uploadFiles(Array.from(event.target.files) as File[]);
  };

  const spinner = withSpinner && isLoading && !disabledByLimit;

  return (
    <div
      className={sm ? s.sm : ''}
      style={style}
      onDragOver={preventEvent}
      onDragEnter={preventEvent}
      onDragLeave={preventEvent}
      onDrop={dropHandler}
    >
      <label htmlFor={id} className={disable || disabledByLimit ? s.disabled : ''}>
        <div className={s.upload_container}>
          {disabledByLimit ? (
            <div className={s.files_limit}>
              <ErrorIcon /> {limitTitle}
            </div>
          ) : (
            <>
              <div className={s.upload_text} data-is-loading={isLoading}>
                <DownloadIcon />
                {!sm && (
                  <>
                    <span className={s.upload_title}>Drag file or</span>
                    <span className={`${s.upload_title} ${s.upload_title_bold}`}>click to upload</span>
                  </>
                )}
              </div>
              {!sm && (
                <span className={s.upload_types} data-is-loading={isLoading}>
                  {acceptType}
                </span>
              )}
            </>
          )}
          {spinner && (
            <div className={s.spinner_container}>
              <Spinner containerClassName={s.spinner} disableShrink size={30} />
            </div>
          )}
        </div>
      </label>
      <input
        hidden
        id={id}
        ref={ref}
        value=""
        type="file"
        accept={acceptType}
        multiple={isMultiple}
        disabled={disabledByLimit || disable}
        onChange={handleChange}
      />
    </div>
  );
}
