import { Autocomplete as MuiAutocomplete, AutocompleteProps, ChipTypeMap } from '@mui/material';
import { TabDownIcon } from 'icons/tab-down';
import { IdName } from 'types/common-types';
import s from './autocomplete.module.scss';

export type Props<Option, ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']> = AutocompleteProps<
  Option,
  boolean | undefined,
  boolean | undefined,
  boolean | undefined,
  ChipComponent
> & {
  className?: string;
  selectedItem?: IdName;
  isHighlightText?: boolean;
};

function Autocomplete<Option, ChipComponent extends React.ElementType = 'div'>({
  className,
  selectedItem,
  isHighlightText,
  ...rest
}: Props<Option, ChipComponent>) {
  const highlightText = (text, query) => {
    const index = text?.name?.toLowerCase()?.indexOf(query.toLowerCase());
    if (index === -1) return text.name;

    return (
      <>
        {text.name.substring(0, index)}
        <span className={s.text_highlight}>{text.name.substring(index, index + query.length)}</span>
        {text.name.substring(index + query.length)}
      </>
    );
  };

  const filterOptions = (options, { inputValue }, selectedOption) => {
    return options.filter(
      (option) => option.id !== selectedOption?.id && option?.name?.toLowerCase()?.includes(inputValue.toLowerCase().trim()),
    );
  };

  return (
    <MuiAutocomplete
      popupIcon={<TabDownIcon />}
      className={`${className || ''} ${s.autocomplete}`}
      filterOptions={(event, { inputValue }) => filterOptions(event, { inputValue }, selectedItem)}
      renderOption={(props, option: Option & IdName, { inputValue }) => {
        return (
          <li {...props} key={option.id}>
            {isHighlightText !== false && selectedItem?.name !== inputValue ? (
              <span>{highlightText(option, inputValue)}</span>
            ) : (
              <span className={option?.name !== inputValue && s.title}>{option.name}</span>
            )}
          </li>
        );
      }}
      {...rest}
    />
  );
}

export default Autocomplete;
