import s from './something-went-wrong.module.scss';
import image from './something-went-wrong.png';

export const SomethingWentWrong = () => {
  return (
    <div className={s.container}>
      <img className={s.image} src={image} alt="Error" />
      <span className={s.title}>Ooops!</span>
      <span className={s.text}>Something went wrong</span>
    </div>
  );
};
