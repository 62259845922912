import { AppState } from 'redux/store';
import { notify } from 'notifications';
import { IdName } from 'types/common-types';
import { Client } from 'services/client.model';
import { StateController } from 'state-controller';
import { OrdersService } from 'services/orders.service';
import { ClientService } from 'services/client.service';
import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';
import { Actions as ProductionWorkflowActions } from 'pages/production-workflow/controllers/production-workflow.controller';
import { Page } from 'pages/production/controllers/production-list-controller/types';
import { loadData } from 'pages/production/components/info-dropdown/use-info-dropdown';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';

export type EditClientModalArgs = {
  page?: Page;
  client: IdName;
  orderId: string;
  productName?: string;
  productionId?: string;
  productionKey?: string;
  isOpenFromOrder?: boolean;
};

export type EditClientModalState = {
  keyword: string;
  isOpen: boolean;
  orderId: string;
  page: Page | null;
  clients: Client[];
  isLoading: boolean;
  isFetching: boolean;
  productName: string;
  initialData: IdName;
  productionId: string;
  productionKey: string;
  selectedClient: IdName;
  isOpenFromOrder: boolean;
  isValueChanged: boolean;
};

const defaultState: EditClientModalState = {
  page: null,
  clients: [],
  orderId: '',
  keyword: '',
  isOpen: false,
  productName: '',
  isLoading: false,
  productionId: '',
  isFetching: false,
  productionKey: '',
  initialData: {
    id: '',
    name: '',
  },
  selectedClient: {
    id: '',
    name: '',
  },
  isOpenFromOrder: false,
  isValueChanged: false,
};

const stateController = new StateController<EditClientModalState>('EDIT_CLIENT_MODAL', defaultState);

export class EditClientActions {
  public static openModal({
    orderId,
    isOpenFromOrder = false,
    productName,
    productionKey,
    client,
    page,
    productionId,
  }: EditClientModalArgs) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }
      try {
        dispatch(stateController.setState({ isFetching: true }));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            page,
            productionId,
            productName,
            isOpen: true,
            orderId,
            productionKey,
            isOpenFromOrder,
            initialData: {
              id: client?.id || '',
              name: client?.name || '',
            },
            selectedClient: {
              id: client?.id || '',
              name: client?.name || '',
            },
          })),
        );
        const { data } = await ClientService.getAllClients('', 0, 9000);
        dispatch(stateController.setState({ clients: data }));
      } finally {
        dispatch(stateController.setState({ isFetching: false }));
      }
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
      setTimeout(() => dispatch(stateController.setState({ ...defaultState })), 100);
    };
  }

  public static onSave() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const { orderId, selectedClient, page, productionId } = getState().production.editClientModal;

        if (selectedClient.id === 'new') {
          const NewClientBody = {
            name: selectedClient.name,
          };
          const { data } = await ClientService.createClient(NewClientBody);

          const updateBody = {
            client_id: data.id,
          };
          await OrdersService.updateOrder(orderId, updateBody);
        } else {
          const body = {
            client_id: selectedClient.id,
          };
          await OrdersService.updateOrder(orderId, body);
        }

        if (page === Page.InfoDropdownWorkflow) {
          await loadData(productionId);
          await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId, disableAdditionalTasksSet: true }));
        }
        if (page === Page.InfoDropdownProduction) {
          await loadData(productionId);
          const customGroupBy = getState().production.filters.groupBy;
          await dispatch(
            ProductionFiltersActions.getProductionsByFilter({
              customGroupBy,
              showFetchEffect: false,
              resetSkipPreserveTake: true,
            }),
          );
        }
        if (page === Page.Production) {
          const customGroupBy = getState().production.filters.groupBy;
          await dispatch(
            ProductionFiltersActions.getProductionsByFilter({
              customGroupBy,
              showFetchEffect: false,
              resetSkipPreserveTake: true,
            }),
          );
        }
        if (page === Page.Workflow) {
          await dispatch(ProductionWorkflowActions.silentLoad({ id: productionId, disableAdditionalTasksSet: true }));
        }

        dispatch(EditClientActions.closeModal());
        notify.success('Successfully updated');
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static switchShowWarning() {
    return async (dispatch, getState: () => AppState) => {
      const { initialData, selectedClient } = getState().production.editClientModal;
      if (initialData.id !== selectedClient.id) {
        dispatch(stateController.setState({ isValueChanged: true }));
      } else {
        dispatch(stateController.setState({ isValueChanged: false }));
      }
    };
  }

  public static onClientChange(value: Client) {
    return async (dispatch) => {
      const selectedClient = {
        id: value.id,
        name: value.name,
      };
      dispatch(stateController.setState({ selectedClient }));
      dispatch(EditClientActions.switchShowWarning());
    };
  }

  public static onTextFieldChange(value: string) {
    return async (dispatch) => {
      const newClient = {
        id: 'new',
        name: value,
      };
      dispatch(stateController.setState({ selectedClient: newClient }));
    };
  }
}

export class EditClientSelectors {
  public static isSaveDisabled(state: AppState) {
    const { isValueChanged } = state.production.editClientModal;
    const isNameEmpty = !state.production.editClientModal.selectedClient.name;

    return !isValueChanged || isNameEmpty;
  }
}

export const reducer = stateController.getReducer();
