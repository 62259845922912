import { FC, SVGProps } from 'react';

export const StarredUserIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M17.1992 6.78921L19.1879 7.66022L17.3807 8.86333L16.5097 10.8521L15.3066 9.04479L13.3178 8.17379L15.1251 6.97067L15.9961 4.98193L17.1992 6.78921Z"
      stroke="#D5951B"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.46615 9.05817C8.38281 9.04984 8.28281 9.04984 8.19115 9.05817C6.20781 8.9915 4.63281 7.3665 4.63281 5.3665C4.63281 3.32484 6.28281 1.6665 8.33281 1.6665C10.3745 1.6665 12.0328 3.32484 12.0328 5.3665C12.0245 7.3665 10.4495 8.9915 8.46615 9.05817Z"
      stroke="#D5951B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.30156 12.1335C2.2849 13.4835 2.2849 15.6835 4.30156 17.0252C6.59323 18.5585 10.3516 18.5585 12.6432 17.0252C14.6599 15.6752 14.6599 13.4752 12.6432 12.1335C10.3599 10.6085 6.60156 10.6085 4.30156 12.1335Z"
      stroke="#D5951B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
