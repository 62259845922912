import { Tooltip } from '@mui/material';
import { useRef, FC, useEffect, useState } from 'react';
import s from './multiline-ellipsis.module.scss';

type OwnProps = {
  lineLimit?: number;
  textClassName?: string;
  children: React.ReactNode;
  containerClassName?: string;
  [key: string]: any;
};

export const MultiLineEllipsis: FC<OwnProps> = ({ containerClassName, textClassName, lineLimit = 2, children, ...rest }) => {
  const spanRef = useRef<HTMLDivElement>(null);
  const [isShowValueTooltip, setIsShowValueTooltip] = useState(false);

  useEffect(() => {
    const moreInWidth = spanRef.current.scrollWidth > spanRef.current.clientWidth;
    const moreInHeight = spanRef.current.scrollHeight > spanRef.current.clientHeight;

    if (moreInWidth || moreInHeight) {
      setIsShowValueTooltip(true);
    } else {
      setIsShowValueTooltip(false);
    }
  }, [children, spanRef]);

  return (
    <Tooltip title={isShowValueTooltip ? children : ''} placement="top-start">
      <div className={`${containerClassName ?? ''} ${s.container}`} {...rest}>
        <span ref={spanRef} style={{ WebkitLineClamp: lineLimit }} className={`${textClassName ?? ''} ${s.text}`}>
          {children}
        </span>
      </div>
    </Tooltip>
  );
};
