import { DoneIcon } from 'icons/done';
import { CopyIcon } from 'icons/copy';
import { NavLink } from 'react-router-dom';
import { Typography, Tooltip, TypographyProps } from '@mui/material';
import { useState, ReactNode, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import s from './breadcrumb-item.module.scss';

type OwnProps = {
  label: string;
  route?: string;
  tooltip?: string;
  icon?: ReactNode;
  isActive?: boolean;
  disabled?: boolean;
  isDeleted?: boolean;
  isClickable?: boolean;
  isAllowedCopy?: boolean;
  additionalContent?: JSX.Element;
  typographyProps?: TypographyProps;
  onClick?: () => void;
};

const BreadcrumbItem = forwardRef<any, OwnProps>(
  (
    {
      icon,
      label,
      route,
      tooltip,
      disabled,
      isActive,
      isDeleted,
      isClickable,
      isAllowedCopy,
      typographyProps,
      additionalContent,
      onClick,
    },
    ref,
  ) => {
    const textRef = useRef<any>(null);
    const [isCopied, setCopied] = useState(false);
    const [tooltipTitle, setTooltipTitle] = useState('');

    const handleCopyClick = async (event: React.MouseEvent) => {
      event.preventDefault();
      await navigator.clipboard.writeText(label);
      setCopied(true);
    };

    const handleMouseLeave = async () => {
      setTimeout(() => setCopied(false), 500);
    };

    useImperativeHandle(
      ref,
      () => ({
        textRef,
      }),
      [textRef],
    );

    useEffect(() => {
      if (textRef) {
        const isShowValueTooltip = textRef.current.scrollWidth > textRef.current.clientWidth;
        setTooltipTitle(isShowValueTooltip ? label : tooltip || '');
      }
    }, [textRef?.current?.scrollWidth, textRef?.current?.clientWidth, label, tooltip]);

    return (
      <NavLink
        end
        onClick={onClick}
        to={disabled ? '' : route}
        className={s.breadcrumb_link}
        data-is-clickable={isClickable}
        onMouseLeave={() => {
          if (isCopied) handleMouseLeave();
        }}
      >
        <>
          <Tooltip placement="top-start" title={tooltipTitle}>
            <div className={s.inner_container}>
              <div
                className={`
                  ${s.text_with_icon} 
                  ${isActive ? s.active : s.ordinary}
                `}
                data-is-deleted={isDeleted}
                data-is-clickable={isClickable}
              >
                {icon && <div className={`${s.icon} ${isActive ? s.active : s.ordinary}`}>{icon}</div>}
                <Typography
                  ref={textRef}
                  className={`
                    ${s.text}
                    ${isActive ? s.active : ''}
                    ${typographyProps?.className}
                  `}
                  {...typographyProps}
                >
                  {label}
                </Typography>
              </div>

              {additionalContent && <div className={s.additional_content}>{additionalContent}</div>}
            </div>
          </Tooltip>

          {isAllowedCopy && (
            <div className={s.copy_icon_container} onClick={(event) => handleCopyClick(event)}>
              {isCopied ? (
                <Tooltip title="Copied" placement="top-start">
                  <div>
                    <DoneIcon height={15} width={15} className={s.done_icon} />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Copy" placement="top-start">
                  <div>
                    <CopyIcon height={15} width={15} className={`${isActive ? s.copy_icon_blue : s.copy_icon}`} />
                  </div>
                </Tooltip>
              )}
            </div>
          )}
        </>
      </NavLink>
    );
  },
);

BreadcrumbItem.displayName = 'BreadcrumbItem';

export default BreadcrumbItem;
