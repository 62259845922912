import { FC, SVGProps } from 'react';

export const SearchIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.4567 18.1996C14.733 18.1996 18.1996 14.733 18.1996 10.4567C18.1996 6.18046 14.733 2.71387 10.4567 2.71387C6.18046 2.71387 2.71387 6.18046 2.71387 10.4567C2.71387 14.733 6.18046 18.1996 10.4567 18.1996Z"
      stroke="#878B92"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21.2849 21.2859L15.9277 15.9287" stroke="#878B92" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
