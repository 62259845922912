import { FC } from 'react';
import { LinePosition } from 'icons/line-position';
import { DepartmentsIcon } from 'icons/departments';
import HighlightText from 'components/highlight-text/highlight-text';
import { TextEllipsis } from 'components/text-ellipsis/text-ellipsis';
import { AvatarCircle } from 'components/ui-new/avatar-circle/avatar-circle';
import s from './option-item.module.scss';

type OwnProps = {
  id?: string;
  keyword?: string;
  last_name: string;
  first_name: string;
  selected?: boolean;
  isUnassign?: boolean;
  noUserMode?: boolean;
  isBiggerPopup?: boolean;
  avatarImageSource?: string;
  position?: { id: string; name: string };
  department?: { id: string; name: string };
  [key: string]: any;
};

const OptionItem: FC<OwnProps> = ({
  keyword,
  selected,
  position,
  last_name,
  noUserMode,
  first_name,
  department,
  isBiggerPopup,
  avatarImageSource,
  isUnassign = false,
  ...rest
}) => {
  const titleMaxWidth = isBiggerPopup ? '340px' : '190px';
  const noInfoText = '-';

  return (
    <div className={`${s.option_container} ${selected ? s.selected : ''}`} {...rest}>
      <AvatarCircle
        lastName={last_name}
        firstName={first_name}
        className={s.option_avatar}
        imageSrc={avatarImageSource}
        isEmpty={!first_name || !last_name}
      />
      <div className={s.option_name}>
        <TextEllipsis maxWidth={titleMaxWidth}>
          <HighlightText text={`${first_name} ${last_name}`} query={keyword || ''} />
        </TextEllipsis>

        {!isUnassign && (
          <div className={s.user_info_container}>
            <DepartmentsIcon width={16} height={16} />
            <TextEllipsis
              className={`${s.user_desc_text} ${s.margin_right}`}
              maxWidth="100%"
              tooltipTitle={department?.name || noInfoText}
            >
              {department?.name || noInfoText}
            </TextEllipsis>

            <LinePosition width={16} height={16} />
            <TextEllipsis className={s.user_desc_text} maxWidth="100%" tooltipTitle={position?.name || noInfoText}>
              {position?.name || noInfoText}
            </TextEllipsis>
          </div>
        )}
      </div>
    </div>
  );
};

export default OptionItem;
