import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';
import s from './success.module.scss';
import shared_s from '../../shared-styles.module.scss';

type Props = {
  path?: string;
  header: string;
  description: ReactNode;
  isShowButton?: boolean;
  onClickCallback?: () => void;
};

const Success = ({ header, description, isShowButton, path, onClickCallback }: Props) => {
  const navigate = useNavigate();

  return (
    <div className={shared_s.success_wrapper}>
      <div className={shared_s.emoji}>✅</div>
      <div className={s.text}>
        <h3>{header}</h3>
        <p>{description}</p>
      </div>
      {isShowButton && (
        <Button
          className={shared_s.button}
          onClick={() => {
            navigate(path);
            if (onClickCallback) onClickCallback();
          }}
        >
          Log in
        </Button>
      )}
    </div>
  );
};

export default Success;
