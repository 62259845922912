import { ProductForSelect, ProductVariantForSelect, PublishedProductWithName } from 'services/products.model';
import { ProductConfigurationForSelect } from 'services/product-configurations.model';
import { StateController } from 'state-controller';
import { ProductsService } from 'services/products.service';
import { Products2Icon } from 'icons/products-2';
import { GetStateFunction } from 'redux/store';
import { ProductConfigurationService } from 'services/product-configurations.service';
import { ProductVariantService } from 'services/product-variant.service';
import { versionName } from 'utils/version-name';
import { findMyParentIds } from 'pages/production/controllers/manage-components-modal.controller/helpers';
import { Component } from 'pages/production/controllers/manage-components-modal.controller/manage-components-modal.controller';
import { OpenNewComponentModalArgs } from 'pages/production/manage-components-modal/components/new-component-modal/types';
import { PaginationData } from 'types/common-types';
import { debounce } from 'utils/debounce';
import { notify } from 'notifications';

export type NewComponentModalState = {
  count: number;
  isOpen: boolean;
  orderKey: string;
  clientName: string;
  parentIds: string[];
  productionKey: string;
  targetSlot: Component;
  productionName: string;
  additionalZoneId: string;
  isOrderCreating: boolean;
  pagination: PaginationData;
  externalOrderNumber: string;
  product: {
    isLoading: boolean;
    value: ProductForSelect;
    options: ProductForSelect[];
  };
  version: {
    isLoading: boolean;
    value: PublishedProductWithName;
    options: PublishedProductWithName[];
  };
  configuration: {
    isLoading: boolean;
    value: ProductConfigurationForSelect;
    options: ProductConfigurationForSelect[];
  };
  variant: {
    isLoading: boolean;
    value: ProductVariantForSelect;
    options: ProductVariantForSelect[];
  };
};

const defaultState: NewComponentModalState = {
  count: 1,
  orderKey: '',
  isOpen: false,
  parentIds: [],
  clientName: '',
  targetSlot: null,
  productionKey: '',
  productionName: '',
  additionalZoneId: '',
  isOrderCreating: false,
  externalOrderNumber: '',
  pagination: {
    total: 0,
    perPage: 10,
    next: 0,
    lastPage: 0,
    currentPage: 1,
  },
  product: {
    isLoading: false,
    value: null,
    options: [],
  },
  version: {
    isLoading: false,
    value: null,
    options: [],
  },
  configuration: {
    isLoading: false,
    value: null,
    options: [],
  },
  variant: {
    isLoading: false,
    value: null,
    options: [],
  },
};

const stateController = new StateController<NewComponentModalState>('COMPONENT_NEW_MODAL', defaultState);

export class NewComponentModalActions {
  public static openNewComponentModal({
    rootId,
    orderKey,
    targetSlot,
    clientName,
    components,
    productionKey,
    productMetaId,
    parentMetaIds,
    productionName,
    additionalZoneId,
    externalOrderNumber,
  }: OpenNewComponentModalArgs) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          orderKey,
          clientName,
          isOpen: true,
          productionKey,
          productionName,
          externalOrderNumber,
        })),
      );

      if (targetSlot) {
        const parentIds = findMyParentIds(components, targetSlot.id, rootId);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            parentIds,
            targetSlot,
            product: {
              ...prev.product,
              isLoading: true,
            },
          })),
        );
      } else if (additionalZoneId) {
        const parentIds = findMyParentIds(components, additionalZoneId, rootId, true);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            parentIds,
            productMetaId,
            additionalZoneId,
            product: {
              ...prev.product,
              isLoading: true,
            },
          })),
        );
      } else if (parentMetaIds) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            parentIds: [...parentMetaIds, productMetaId],
            productMetaId,
            product: {
              ...prev.product,
              isLoading: true,
            },
          })),
        );
      }
      dispatch(NewComponentModalActions.initProductsWithVersions());
    };
  }

  public static initProductsWithVersions() {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        const { parentIds } = getState().production.newComponentModal;
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              isLoading: true,
            },
          })),
        );
        const { data, meta } = await ProductsService.getProductWithVersion();

        const options: ProductForSelect[] = data.map((product) => ({
          id: product.id,
          name: product.name,
          breadcrumbs: product.category_path,
          is_active: product.is_active,
          product_meta_id: product.product_meta_id,
          published_products: product.published_products,
          icon: <Products2Icon />,
        }));

        const filteredOptions = options.filter((i) => !parentIds.includes(i.product_meta_id));

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            pagination: {
              total: meta.total,
              currentPage: meta.currentPage,
              perPage: meta.perPage,
              lastPage: meta.lastPage,
              next: meta.next,
            },
            product: {
              ...prev.product,
              options: filteredOptions,
              isLoading: false,
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static initProductConfigurations() {
    return async (dispatch, getState: GetStateFunction) => {
      const productId = getState().production.newComponentModal.version.value.id;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          configuration: {
            ...prev.configuration,
            isLoading: true,
          },
        })),
      );
      const productConfigurations = await ProductConfigurationService.getAll(productId);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          configuration: {
            ...prev.configuration,
            value: productConfigurations?.sort((config1, config2) => config1.order - config2.order)[0],
            options: productConfigurations,
            isLoading: false,
          },
        })),
      );
    };
  }

  public static initProductVariants() {
    return async (dispatch, getState: GetStateFunction) => {
      const configurationId = getState().production.newComponentModal.configuration.value.id;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            variant: {
              ...prev.variant,
              isLoading: true,
            },
          })),
        );
        const productVariants = await ProductVariantService.getAll(configurationId);
        const options = productVariants
          .filter((i) => i.is_active === true)
          .map((variant) => ({
            id: variant.id,
            name: `${variant.name} - ${variant.sku} - ${variant.barcode}`,
            is_active: variant.is_active,
          }));
        const defaultVariant = options[0];
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            variant: {
              ...prev.variant,
              value: defaultVariant,
              options,
              isLoading: false,
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            variant: {
              ...prev.variant,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static loadMoreOrSearchProductsWithVersions(value: string = '', isScroll: boolean = false) {
    return async (dispatch, getState: GetStateFunction) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            pagination: {
              ...prev.pagination,
              next: 0,
            },
          })),
        );
      }
      const { pagination, parentIds } = getState().production.newComponentModal;
      if (isScroll && pagination.currentPage >= pagination.lastPage) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              isLoading: true,
            },
          })),
        );
        debounce(async () => {
          const { data, meta } = await ProductsService.getProductWithVersion(value.trim(), pagination.next, pagination.perPage);

          const options: ProductForSelect[] = data.map((product) => ({
            id: product.id,
            name: product.name,
            breadcrumbs: product.category_path,
            is_active: product.is_active,
            product_meta_id: product.product_meta_id,
            published_products: product.published_products,
            icon: <Products2Icon />,
          }));

          const filteredOptions = options.filter((i) => !parentIds.includes(i.product_meta_id));

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              product: {
                ...prev.product,
                options: isScroll ? [...prev.product.options, ...filteredOptions] : filteredOptions,
                isLoading: false,
              },
              pagination: {
                ...prev.pagination,
                currentPage: meta.currentPage,
                lastPage: meta.lastPage,
                total: meta.total,
                perPage: meta.perPage,
                next: meta.next,
              },
            })),
          );
        }, 500);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              isLoading: false,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static onValueProductChange(value: NewComponentModalState['product']['value']) {
    return (dispatch) => {
      const productVersions = value.published_products.map((productVersion) => ({
        ...productVersion,
        name: versionName(productVersion.version),
      }));
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product: {
            ...prev.product,
            value,
          },
          version: {
            ...prev.version,
            value: null,
            options: productVersions,
          },
          configuration: {
            ...prev.version,
            value: null,
            options: [],
          },
          variant: {
            ...prev.version,
            value: null,
            options: [],
          },
        })),
      );
      dispatch(NewComponentModalActions.onValueVersionChange(productVersions[0]));
    };
  }

  public static onValueVersionChange(value: NewComponentModalState['version']['value']) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          version: {
            ...prev.version,
            value,
          },
          configuration: {
            ...prev.version,
            value: null,
            options: [],
          },
          variant: {
            ...prev.version,
            value: null,
            options: [],
          },
        })),
      );
      await dispatch(NewComponentModalActions.initProductConfigurations());
      dispatch(NewComponentModalActions.initProductVariants());
    };
  }

  public static onValueConfigurationChange(value: NewComponentModalState['configuration']['value']) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          configuration: {
            ...prev.configuration,
            value,
          },
          variant: {
            ...prev.version,
            value: null,
          },
        })),
      );
      dispatch(NewComponentModalActions.initProductVariants());
    };
  }

  public static onValueVariantChange(value: NewComponentModalState['variant']['value']) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          variant: {
            ...prev.variant,
            value,
          },
        })),
      );
    };
  }

  public static closeModal() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static setFetching(value: boolean) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOrderCreating: value }));
    };
  }
}

export const NewComponentModalReducer = stateController.getReducer();
