import { baseAxiosInstance } from 'axios-config';
import {
  WorkflowTemplateItem,
  CreateWorkflowTemplateItemData,
  UpdateWorkflowTemplateItemData,
  UpdateMultipleTemplateItemData,
} from 'services/workflow-template-items.model';

export class WorkflowTemplateItemsService {
  public static async getAllWorkflowTemplates() {
    const { data } = await baseAxiosInstance.get<WorkflowTemplateItem[]>('/workflow-template-items/all');

    return data;
  }

  public static async getAllWorkflowById(id: string) {
    const { data } = await baseAxiosInstance.get<WorkflowTemplateItem>(`/workflow-template-items/${id}`);

    return data;
  }

  public static async create(body: CreateWorkflowTemplateItemData) {
    const { data } = await baseAxiosInstance.post<WorkflowTemplateItem>('/workflow-template-items/create', body);

    return data;
  }

  public static async duplicate(body: { id: string }) {
    const { data } = await baseAxiosInstance.post<WorkflowTemplateItem>('/workflow-template-items/duplicate', body);

    return data;
  }

  public static async update(id: string, body: UpdateWorkflowTemplateItemData) {
    const { data } = await baseAxiosInstance.put<WorkflowTemplateItem>(`/workflow-template-items/${id}`, body);

    return data;
  }

  public static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete<WorkflowTemplateItem>(`/workflow-template-items/${id}`);

    return data;
  }

  public static async deleteMultiple(body: { Ids: string[] }) {
    const { data } = await baseAxiosInstance.post(`/workflow-template-items/delete-many`, body);

    return data;
  }

  public static async updateMultiple(body: UpdateMultipleTemplateItemData[]) {
    const { data } = await baseAxiosInstance.put(`/workflow-template-items/update-many`, body);

    return data;
  }
}
