import { StateController } from 'state-controller';
import { UserWithPositionSlot } from 'services/user.model';
import { User } from 'services/users-filter.model';
import { UserStatusEnum } from 'types/status-enums';
import { AppState } from 'redux/store';
import { notify } from 'notifications';
import { toast } from 'react-toastify';
import { Tenant } from 'services/tenant.model';
import { TenantService } from 'services/tenant.service';
import { PositionSlotByUserId } from 'services/position-slots.model';
import { Actions as ChangeUserPasswordModalActiopns } from 'modules/change-user-password/change-user-password.controller';
import { AuthService } from 'services/auth.service';
import { VacationService } from 'services/vacation.service';
import { DayOff } from 'services/vacation.model';
import { doDateRangesIntersect } from 'utils/date-intersect';
import { UserService } from '../../services/user.service';

export enum TabEnum {
  General = 'General',
  Security = 'Security',
  Position = 'Position',
  Permissions = 'Permissions',
  DayOf = 'DayOf',
}

export type UserState = Partial<
  UserWithPositionSlot &
    User & {
      isModalOpen: boolean;
      activeTab: TabEnum;

      userTenants: Tenant[];
      isUploadLoading?: boolean;
      isSendLoading?: boolean;
      dayOfComment: string;
      dayOfStartDate: string;
      dayOfEndDate: string;
      editDayOfComment: string;
      editDayOfStartDate: string;
      editDayOfEndDate: string;
      createDayOfLoading: boolean;
      editDayOfLoading: boolean;
      deleteLoading: boolean;
      isChangePasswordLoading: boolean;
      isFetching: boolean;
    }
>;

const defaultState: UserState = {
  isModalOpen: false,
  activeTab: TabEnum.General,
  phone: '',
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  avatar_image_url: '',
  is_active: false,
  userVacations: [],
  userTenants: [],
  status: UserStatusEnum.Active,
  positions: [],
  created_at: '',
  invited_at: '',
  isUploadLoading: false,
  isSendLoading: false,
  dayOfComment: '',
  dayOfStartDate: '',
  dayOfEndDate: '',
  editDayOfComment: '',
  editDayOfStartDate: '',
  editDayOfEndDate: '',
  external_user_id: '',
  createDayOfLoading: false,
  editDayOfLoading: false,
  deleteLoading: false,
  isFetching: false,
  isChangePasswordLoading: false,
};

const stateController = new StateController<UserState>('USER_STATE', defaultState);

export class Actions {
  public static init(id: string) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isFetching: true }));
      const data = await UserService.getUserById(id);
      const tenantData = await TenantService.getTenantsByUser(id);
      const vacationData = await VacationService.getUserDayOff(id);
      dispatch(stateController.setState({ ...data, userVacations: vacationData, userTenants: tenantData || [] }));
      dispatch(stateController.setState({ isFetching: false }));
    };
  }

  public static setActiveTab(newTab: TabEnum) {
    return (dispatch) => {
      dispatch(stateController.setState({ activeTab: newTab }));
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static setUser(userData) {
    return (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, ...userData, isUploadLoading: false, isSendLoading: false })));
    };
  }

  public static setUserAvatar(id: string, userData: FormData) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isUploadLoading: true }));
      const data = await UserService.updateUser(id, userData);
      dispatch(Actions.setUser(data));
      return data;
    };
  }

  public static setStatus(id: string, status: UserStatusEnum) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { email, first_name, last_name, phone, avatar_image_url } = getState().user;
        const userData = new FormData();
        userData.append('first_name', first_name);
        userData.append('last_name', last_name);
        userData.append('email', email);
        if (phone?.length > 0) {
          userData.append('phone', phone);
        }
        userData.append('avatar_image_url', avatar_image_url);
        userData.append('status', status);

        const data = await UserService.updateUser(id, userData);
        dispatch(Actions.setUser(data));
      } catch (error) {
        toast(error.message);
      }
    };
  }

  public static resendInvitation() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isSendLoading: true }));
        const { email } = getState().user;
        await UserService.sendInvitation(email);
        dispatch(stateController.setState({ invited_at: new Date().toISOString() }));
      } catch (error) {
        toast(error.message);
      } finally {
        dispatch(stateController.setState({ isSendLoading: false }));
      }
    };
  }

  public static sendResetPasswordEmail() {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState({ isSendLoading: true }));
      try {
        const { email } = getState().user;
        await AuthService.sendResetPasswordEmail({ email });
        notify.success('Succesfully sended!');
      } catch (error) {
        toast(error.message);
      } finally {
        dispatch(stateController.setState({ isSendLoading: false }));
      }
    };
  }

  public static changePassword() {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState({ isChangePasswordLoading: true }));
      const { id, email, first_name, last_name, avatar_image_url, phone, status } = getState().user;
      const { newPassword, confirmPassword } = getState().changeUserPassword;
      const userData = new FormData();
      userData.append('first_name', first_name);
      userData.append('last_name', last_name);
      userData.append('email', email);
      if (phone?.length > 0) {
        userData.append('phone', phone);
      }
      userData.append('avatar_image_url', avatar_image_url);
      userData.append('password', newPassword);
      userData.append('confirmPassword', confirmPassword);
      userData.append('status', status);
      try {
        const user = await UserService.updateUser(id, userData);
        dispatch(stateController.setState({ status: user.status }));
        dispatch(ChangeUserPasswordModalActiopns.closeChangeUserPasswordModal());
        notify.success('Successfully updated');
      } catch (error) {
        /* empty */
      } finally {
        dispatch(stateController.setState({ isChangePasswordLoading: false, invited_at: null }));
      }
    };
  }

  public static onDayOfInputValueChange(key: string, value: string) {
    return (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, [key]: value })));
    };
  }

  public static createDayOf() {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState((prev) => ({ ...prev, createDayOfLoading: true })));
      const {
        id: user_id,
        dayOfComment: comment,
        dayOfStartDate: start_at,
        dayOfEndDate: end_at,
        userVacations: vacations,
      } = getState().user;
      const isIntersect = vacations.map((item) => doDateRangesIntersect(start_at, end_at, item.start_at, item.end_at));
      if (isIntersect.some((item) => item)) {
        notify.error('Dates of different days off should not overlap');
        dispatch(
          stateController.setState({
            createDayOfLoading: false,
          }),
        );
        return;
      }

      const data = await VacationService.create({ user_id, comment, start_at, end_at });
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          userVacations: [data, ...prev.userVacations],
          createDayOfLoading: false,
          dayOfStartDate: '',
          dayOfComment: '',
          dayOfEndDate: '',
        })),
      );
    };
  }

  public static clearDayOfFields() {
    return async (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, dayOfComment: '', dayOfEndDate: '', dayOfStartDate: '' })));
    };
  }

  public static deleteVacation(id: string) {
    return async (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, deleteLoading: true })));
      await VacationService.delete(id);
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          userVacations: prev.userVacations.filter((vacation) => vacation.id !== id),
          deleteLoading: false,
        })),
      );
    };
  }

  public static fillDayOfValues(vacation: DayOff) {
    return async (dispatch) => {
      const { comment, end_at, start_at } = vacation;
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          editDayOfComment: comment,
          editDayOfEndDate: end_at,
          editDayOfStartDate: start_at,
        })),
      );
    };
  }

  public static editVacation(id: string, onSuccess: () => void) {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState((prev) => ({ ...prev, editDayOfLoading: true })));
      const { id: user_id, editDayOfComment: comment, editDayOfStartDate: start_at, editDayOfEndDate: end_at } = getState().user;
      const data = await VacationService.edit(id, { comment, start_at, end_at, user_id });
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          userVacations: prev.userVacations.map((vacation: DayOff) => {
            if (vacation.id === id) {
              return data;
            }
            return vacation;
          }),
          editDayOfLoading: false,
        })),
      );
      onSuccess();
    };
  }
}

export class Selectors {
  public static getPosition(state: AppState) {
    const { positions } = state.user;
    if (positions && positions.length) {
      const pos = positions[0];
      const slotName = pos.positionSlotName ? `(${pos.positionSlotName})` : '';
      return `${pos.positionTypeName} ${slotName}`;
    }
    return '-';
  }

  public static getDepartment(state: AppState) {
    const { positions } = state.user;
    if (positions && positions.length) {
      return (positions as PositionSlotByUserId[])
        .map((item) => item.departmentPath[item.departmentPath.length - 1].name)
        .join(', ');
    }
    return '-';
  }

  public static getDepartmentId(state: AppState) {
    const { positions } = state.user;
    if (positions && positions.length) {
      return (positions as PositionSlotByUserId[]).map((item) => item.departmentPath[item.departmentPath.length - 1].id);
    }
    return [];
  }
}

export const reducer = stateController.getReducer();
