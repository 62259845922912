import { baseAxiosInstance } from 'axios-config';
import { UserStatusEnum } from 'types/status-enums';
import { PaginatedResponse } from 'types/common-types';
import { AssignUser, CurrentUser, User, UserWithPositionSlot } from './user.model';
import { GetProductionWorkflowsFilteredRequest } from './production-workflow.model';

export class UserService {
  public static async getAllUsers({
    skip,
    take,
    search,
    user_id,
    department_id,
    position_type_id,
    status,
  }: {
    skip?: number;
    take?: number;
    search?: string;
    user_id?: string;
    department_id?: string;
    position_type_id?: string;
    status?: UserStatusEnum;
  } = {}): Promise<PaginatedResponse<User[]>> {
    const { data } = await baseAxiosInstance.get('/users/all', {
      params: {
        skip,
        take,
        search,
        user_id,
        department_id,
        position_type_id,
        status,
      },
    });
    return data;
  }

  public static async getUserById(userId: string): Promise<UserWithPositionSlot> {
    const { data } = await baseAxiosInstance.get(`/users/${userId}`);
    return data;
  }

  public static async getCurrentUser() {
    const { data } = await baseAxiosInstance.get<CurrentUser>('/users/me');
    return data;
  }

  public static async createUser(newUserData: FormData): Promise<User> {
    const { data } = await baseAxiosInstance.post('/users/create', newUserData);
    return data;
  }

  public static async updateUser(userId: string, updatedUserData: FormData): Promise<any> {
    const { data } = await baseAxiosInstance.put(`/users/${userId}`, updatedUserData);
    return data;
  }

  public static async asignUser(newUserData: AssignUser): Promise<AssignUser> {
    const { data } = await baseAxiosInstance.put('/user/assign-role', newUserData);
    return data;
  }

  public static async deleteUser(userId: string) {
    return baseAxiosInstance.delete(`/users/${userId}`);
  }

  public static async updateUserStatus(id: string, status: UserStatusEnum) {
    const data = await baseAxiosInstance.put(`/users/${id}`, { status });
    return data;
  }

  public static async sendInvitation(email: string) {
    await baseAxiosInstance.post('/users/send-invitation', { email });
  }

  public static async getUsersWithoutPosition(value?: string) {
    const { data } = await baseAxiosInstance.get<User[]>('/users/all-without-position', {
      params: {
        search: value,
      },
    });
    return data;
  }

  public static async getUserProductionFilters() {
    const { data } = await baseAxiosInstance.get<GetProductionWorkflowsFilteredRequest>('/users/production-filters');
    return data;
  }
}
