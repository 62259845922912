import { CalculateRemainingTimeParamsT } from 'utils/to-hours-minutes/types';

export const toHoursMinutesAndSeconds = (totalSeconds: number): { hours: number; minutes: number; seconds: number } => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return { hours, minutes, seconds };
};

export const toMinutes = (hours: number, minutes: number): number => {
  return hours * 60 + minutes;
};

export const toSeconds = (hours: number, minutes: number, seconds: number): number => {
  return hours * 3600 + minutes * 60 + seconds;
};

export const timeDifference = (timeLimit: number, timeTracked: number) => {
  const totalSeconds = Math.abs(timeLimit - timeTracked);

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const isOvertime = timeTracked > timeLimit;

  return { hours, minutes, seconds, isOvertime };
};

export const twoDigitFormat = (time: number): string => {
  return time < 10 ? `0${time}` : `${time}`;
};

export const calculateRemainingTime = ({
  totalHrs,
  totalMin,
  totalSec,
  limitHrs,
  limitMin,
  limitSec,
  isOvertime,
}: CalculateRemainingTimeParamsT) => {
  let hours = isOvertime ? totalHrs - limitHrs : limitHrs - totalHrs;
  let minutes = isOvertime ? totalMin - limitMin : limitMin - totalMin;
  let seconds = isOvertime ? totalSec - limitSec : limitSec - totalSec;

  if (seconds < 0) {
    seconds += 60;
    minutes -= 1;
  }

  if (minutes < 0) {
    minutes += 60;
    hours -= 1;
  }

  return { hours, minutes, seconds };
};
