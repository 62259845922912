type Args = {
  query: string;
  value: string;
};

export class Url {
  static getParameters<T extends string | string[]>(values: T): T {
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);

    if (Array.isArray(values)) {
      return values.map((value) => params.get(value)).filter((value) => value) as T;
    }

    return params.get(values) as T;
  }

  static setParameters(args: Args[] | Args) {
    const currentState = window.history.state;
    const currentUrl = new URL(window.location.href);

    if (Array.isArray(args)) {
      for (let i = 0; i < args.length; i += 1) {
        currentUrl.searchParams.set(args[i].query, args[i].value);
      }
    } else {
      currentUrl.searchParams.set(args.query, args.value);
    }

    window.history.pushState(currentState, document.title, currentUrl.toString());
  }

  static clear() {
    window.history.replaceState({}, document.title, window.location.pathname);
  }
}
