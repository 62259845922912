import { SingleOptionEnum } from 'pages/product-search/filter-enums';
import { FiltersType, SortState } from 'pages/product-search/product-search.controller';
import { IdName } from 'types/common-types';

export const getSingleOption = (status: string) => {
  switch (status) {
    case SingleOptionEnum.Yes:
      return true;
    case SingleOptionEnum.No:
      return false;
    case SingleOptionEnum.All:
      return undefined;
    default:
      return undefined;
  }
};

export const returnIds = (values: IdName[]) => {
  return values.map((value) => value.id);
};

export const createFilterOptions = (filters: FiltersType, sort: SortState, skip: number, take: number) => {
  const containsDraftId = filters.production_status.value as IdName;
  const isAvailableId = filters.production_status.value as IdName;

  return {
    skip,
    take,
    name: filters.keyword.trim(),
    order_by: sort.sort_by.value.id,
    order_direction: sort.sort_order.value,
    is_available: getSingleOption(isAvailableId.id),
    contains_draft: getSingleOption(containsDraftId.id),
    sku: returnIds(filters.sku.value as IdName[]).join(','),
    parameters: returnIds(filters.options.value as IdName[]).join(','),
    product_tag_id: returnIds(filters.tags.value as IdName[]).join(','),
    product_vendor_id: returnIds(filters.vendor.value as IdName[]).join(','),
    product_name: returnIds(filters.product_name.value as IdName[]).join(','),
    product_category_id: returnIds(filters.category.value as IdName[]).join(','),
    publish_date_end: filters.publish_date.value[1] && decodeURIComponent(filters.publish_date.value[1]),
    publish_date_start: filters.publish_date.value[0] && decodeURIComponent(filters.publish_date.value[0]),
    last_update_date_end: filters.last_update.value[1] && decodeURIComponent(filters.last_update.value[1]),
    last_update_date_start: filters.last_update.value[0] && decodeURIComponent(filters.last_update.value[0]),
  };
};
