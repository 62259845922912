import { AppState } from 'redux/store';
import {
  LogType,
  LogItemTypes,
  AddedNewLogItem,
  ReplacedLogItem,
  MovedOutLogItem,
  MoveItemLogTypes,
  MovedToEmptySlotLogItem,
  MovedToAdditionalLogItem,
} from 'pages/production/controllers/types';
import { StateController } from 'state-controller';
import { MODALS } from 'modules/root-modals/modals';
import { FilledWarningIcon } from 'icons/filled-warning';
import { ProductionStatusEnum } from 'types/status-enums';
import { ProductionWorkflow } from 'services/production-workflow.model';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { SLOT, EMPTY_SLOT } from 'pages/production/controllers/constants';
import {
  findSlotIds,
  processLogItems,
  swapProductions,
  findComponentById,
  filterUnusableLogs,
  getSelectedProductions,
  updateNestedComponents,
  addMetaDataToComponents,
  deleteProductionFromArray,
  createComponentInEmptySlot,
  collectAdditionalComponents,
  getAllAdditionalComponentsFromProductionTree,
  removeAdditionalZoneIdsFromTheProductionTree,
} from 'pages/production/controllers/manage-components-modal.controller/helpers';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';
import { NewComponentModalActions } from 'pages/production/manage-components-modal/components/new-component-modal/new-component-modal.controller';

export type Component = ProductionWorkflow & {
  additionalZoneId?: string;
  isComponentMoved?: boolean;
  parentStatus?: ProductionStatusEnum;
};
export type MoveToAdditionalArgs = {
  component: Component;
  createEmpty?: boolean;
  additionalZoneId: string;
  moveOnlyAdditionalComponents?: boolean;
};

export type ManageComponentsModalState = {
  isOpen: boolean;
  items: Component[];
  isItemsChanged: boolean;
  metaIdsOfActiveItem: string[];
  initiallyEmptySlotsIds: string[];
  additionalComponents: Record<string, Component[]>;
  logs: {
    move: MoveItemLogTypes[];
    addNew: AddedNewLogItem[];
  };
};

const defaultState: ManageComponentsModalState = {
  items: [],
  isOpen: false,
  isItemsChanged: false,
  metaIdsOfActiveItem: [],
  additionalComponents: {},
  initiallyEmptySlotsIds: [],
  logs: {
    move: [],
    addNew: [],
  },
};

const stateController = new StateController<ManageComponentsModalState>('MANAGE_COMPONENTS_MODAL', defaultState);

export class ManageComponentsActions {
  public static openModal() {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState({ isOpen: true }));

      const { parentIdsOfSelectedProductions, productionItems } = getState().production.productionList;

      const selectedProductions = getSelectedProductions(productionItems.data, parentIdsOfSelectedProductions);
      const additionalComponents = collectAdditionalComponents(selectedProductions);
      const formattedProductions = addMetaDataToComponents({ productions: selectedProductions });
      const slotsIdsPartOne = findSlotIds(formattedProductions);
      const slotIdsPartTwo = Object.values(additionalComponents).flatMap(findSlotIds);

      const initiallyEmptySlotsIds = [...slotsIdsPartOne, ...slotIdsPartTwo];

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          items: formattedProductions,
          additionalComponents,
          initiallyEmptySlotsIds,
        })),
      );
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
      setTimeout(() => dispatch(stateController.setState({ ...defaultState })), 100);
    };
  }

  public static addLogItem(item: LogItemTypes, logType: LogType) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          logs: {
            ...prev.logs,
            [logType]: [...prev.logs[logType], item],
          },
        })),
      );
    };
  }

  public static moveToAdditionalComponents({
    component,
    additionalZoneId,
    createEmpty = true,
    moveOnlyAdditionalComponents = false,
  }: MoveToAdditionalArgs) {
    return async (dispatch, getState: () => AppState) => {
      const { items, additionalComponents } = getState().production.manageComponentsModal;
      let components = [];
      let updatedItems = [];
      const targetComponent = findComponentById(items, additionalZoneId);

      if (createEmpty) {
        updatedItems = deleteProductionFromArray(items, component.id);
      } else {
        updatedItems = deleteProductionFromArray(items, component.id, false);
      }

      const allAdditionalComponents = getAllAdditionalComponentsFromProductionTree({
        component,
        additionalComponents,
        removeAdditionalComponents: (id) => dispatch(ManageComponentsActions.removeAdditionalComponents(id)),
      });

      const componentsWithMetadata = addMetaDataToComponents({
        productions: [component],
        additionalZoneId,
        isComponentMoved: true,
        status: targetComponent.status,
      });
      const additionalComponentsWithMetadata = addMetaDataToComponents({
        productions: allAdditionalComponents,
        additionalZoneId,
        isComponentMoved: true,
        status: targetComponent.status,
      });

      if (moveOnlyAdditionalComponents) {
        components = additionalComponentsWithMetadata;
      } else {
        components = [...componentsWithMetadata, ...additionalComponentsWithMetadata];
      }

      dispatch(ManageComponentsActions.addAdditionalComponents(components, additionalZoneId));
      dispatch(stateController.setState({ items: updatedItems }));

      if (!moveOnlyAdditionalComponents && createEmpty) {
        const logItem: MovedToAdditionalLogItem = {
          attached_to: additionalZoneId,
          production_workflow_id: component.id,
          is_moved_to_additional_components: true,
          is_moved_from_additional_components: false,
          detached_from: component.parent_production_workflow_id,
          detached_from_slot: component.nestedProductionWorkflowItems[0].id,
        };
        dispatch(ManageComponentsActions.addLogItem(logItem, LogType.Moved));
      }
      if (!moveOnlyAdditionalComponents && !createEmpty) {
        const logItem: MovedToAdditionalLogItem = {
          attached_to: additionalZoneId,
          production_workflow_id: component.id,
          is_moved_to_additional_components: true,
          is_moved_from_additional_components: false,
          detached_from: component.parent_production_workflow_id,
          detached_from_slot: component.nestedProductionWorkflowItems[0]?.id || null,
        };
        dispatch(ManageComponentsActions.addLogItem(logItem, LogType.Moved));
      }
    };
  }

  public static removeAdditionalComponents(additionalZoneId: string, componentId?: string, isNested?: boolean) {
    return async (dispatch, getState: () => AppState) => {
      const items = getState().production.manageComponentsModal.additionalComponents[additionalZoneId];

      let updatedItems: Component[];
      if (isNested) {
        updatedItems = deleteProductionFromArray(items, componentId, true);
      } else {
        updatedItems = deleteProductionFromArray(items, componentId, false);
      }

      if (componentId) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            additionalComponents: {
              ...prev.additionalComponents,
              [additionalZoneId]: updatedItems,
            },
          })),
        );
      } else {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            additionalComponents: {
              ...prev.additionalComponents,
              [additionalZoneId]: [],
            },
          })),
        );
      }
    };
  }

  public static moveFromAdditionalToAdditional(component: Component, additionalZoneId: string, targetAdditionalZoneId: string) {
    return async (dispatch) => {
      const isNested = Boolean(component.parent_production_workflow_id);

      dispatch(ManageComponentsActions.removeAdditionalComponents(additionalZoneId, component.id, isNested));
      dispatch(ManageComponentsActions.addAdditionalComponents([component], targetAdditionalZoneId));

      const logItem: MovedToAdditionalLogItem = {
        detached_from_slot: null,
        attached_to: targetAdditionalZoneId,
        production_workflow_id: component.id,
        is_moved_to_additional_components: true,
        is_moved_from_additional_components: true,
        detached_from: component.parent_production_workflow_id || additionalZoneId,
      };
      dispatch(ManageComponentsActions.addLogItem(logItem, LogType.Moved));
    };
  }

  public static moveOut(component: Component, additionalZoneId?: string) {
    return async (dispatch, getState: () => AppState) => {
      const { items } = getState().production.manageComponentsModal;
      const formattedComponent: Component = {
        ...component,
        main_root_id: null,
        parent_production_workflow_id: null,
      };

      if (additionalZoneId) {
        const isNested = Boolean(component.parent_production_workflow_id);

        dispatch(ManageComponentsActions.removeAdditionalComponents(additionalZoneId, component.id, isNested));
        const componentWithoutAdditionalZoneIds = removeAdditionalZoneIdsFromTheProductionTree(formattedComponent);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            items: [...items, componentWithoutAdditionalZoneIds],
          })),
        );
      } else {
        const updatedItems = deleteProductionFromArray(items, component.id);
        dispatch(stateController.setState({ items: [...updatedItems, formattedComponent] }));
      }

      const logItem: MovedOutLogItem = {
        attached_to: null,
        is_moved_out_of_root: true,
        production_workflow_id: component.id,
        is_moved_from_additional_components: Boolean(additionalZoneId),
        // additionalZoneId needed for when additional component is moved out. additionalZoneId === parent_production_workflow_id
        detached_from: component.parent_production_workflow_id || additionalZoneId,
        detached_from_slot: additionalZoneId ? null : component.nestedProductionWorkflowItems[0].id,
      };
      dispatch(ManageComponentsActions.addLogItem(logItem, LogType.Moved));
    };
  }

  public static moveAdditionalComponentToEmptySlot(component: Component, target: Component, additionalZoneId: string) {
    return async (dispatch, getState: () => AppState) => {
      const { items } = getState().production.manageComponentsModal;

      const componentCopy = { ...component };
      delete componentCopy.additionalZoneId;

      dispatch(ManageComponentsActions.removeAdditionalComponents(additionalZoneId, component.id));
      const updatedArray = createComponentInEmptySlot(items, componentCopy, target);
      dispatch(stateController.setState({ items: updatedArray }));

      const slotId = target.id.split('_')[1];
      const logItem: MovedToEmptySlotLogItem = {
        detached_from_slot: null,
        detached_from: additionalZoneId,
        production_workflow_id: component.id,
        is_moved_from_additional_components: true,
        production_workflow_item_id_to_attach: slotId,
        attached_to: target.parent_production_workflow_id,
      };
      dispatch(ManageComponentsActions.addLogItem(logItem, LogType.Moved));
    };
  }

  public static moveComponent(component: Component, target: Component, createEmpty: boolean = true) {
    return async (dispatch, getState: () => AppState) => {
      const { items, additionalComponents } = getState().production.manageComponentsModal;
      const isTargetEmpty = target?.id?.startsWith(SLOT);

      if (additionalComponents[component.id]?.length) {
        dispatch(
          ManageComponentsActions.moveToAdditionalComponents({
            component,
            additionalZoneId: component.id,
            moveOnlyAdditionalComponents: true,
          }),
        );
      }

      if (isTargetEmpty) {
        let stepOneArray: Component[];
        if (createEmpty) {
          stepOneArray = deleteProductionFromArray(items, component.id);
        } else {
          stepOneArray = deleteProductionFromArray(items, component.id, false);
        }
        const updatedArray = createComponentInEmptySlot(stepOneArray, component, target);
        dispatch(stateController.setState({ items: updatedArray }));

        const slotId = target.id.split('_')[1];
        const logItem: MovedToEmptySlotLogItem = {
          production_workflow_id: component.id,
          is_moved_from_additional_components: false,
          production_workflow_item_id_to_attach: slotId,
          attached_to: target.parent_production_workflow_id,
          detached_from: component.parent_production_workflow_id,
          detached_from_slot: component.nestedProductionWorkflowItems[0]?.id || null,
        };
        dispatch(ManageComponentsActions.addLogItem(logItem, LogType.Moved));
      } else {
        const updatedArray = swapProductions(items, component, target);
        dispatch(stateController.setState({ items: updatedArray }));

        const logItem: ReplacedLogItem = {
          production_workflow_id: component.id,
          is_moved_from_additional_components: false,
          production_workflow_id_to_replace: target.id,
          attached_to: target.parent_production_workflow_id,
          detached_from: component.parent_production_workflow_id,
          detached_from_slot: component.nestedProductionWorkflowItems[0].id,
          production_workflow_item_id_to_attach: target?.nestedProductionWorkflowItems?.[0].id,
        };
        dispatch(ManageComponentsActions.addLogItem(logItem, LogType.Moved));
      }
    };
  }

  public static saveChanges() {
    return async (dispatch, getState: () => AppState) => {
      const { logs } = getState().production.manageComponentsModal;
      const customGroupBy = getState().production.filters.groupBy;

      const preparedData = [...logs.addNew, ...processLogItems(logs.move)];
      await ProductionWorkflowService.manageComponents(preparedData);
      await dispatch(
        ProductionFiltersActions.getProductionsByFilter({
          customGroupBy,
          showFetchEffect: false,
          resetSkipPreserveTake: true,
        }),
      );
      dispatch(ManageComponentsActions.closeModal());
    };
  }

  public static openConfirmationModal() {
    return async (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Are you sure want to change components in selected production?',
            text: (
              <>
                You can not undo this actions.
                <br />
                Please check order and client info for items you dropped out of production.
              </>
            ),
            icon: <FilledWarningIcon />,
            actionText: 'Apply',
            withCloseButton: false,
            backgroundColor: '#FCE8C0',
            actionButtonColor: 'primary',
            action: () => dispatch(ManageComponentsActions.saveChanges()),
          },
        }),
      );
    };
  }

  public static addAdditionalComponents(components: Component[], additionalZoneId: string) {
    return async (dispatch, getState: () => AppState) => {
      const { additionalComponents } = getState().production.manageComponentsModal;

      const formattedComponents: Component[] = components.map((item) => ({
        ...item,
        additionalZoneId,
        parent_production_workflow_id: null,
        nested_workflows: item.nested_workflows.map((i) => updateNestedComponents(i, item.main_root_id, additionalZoneId)),
      }));

      if (additionalComponents[additionalZoneId]?.length) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            additionalComponents: {
              ...prev.additionalComponents,
              [additionalZoneId]: [...prev.additionalComponents[additionalZoneId], ...formattedComponents],
            },
          })),
        );
      } else {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            additionalComponents: {
              ...prev.additionalComponents,
              [additionalZoneId]: formattedComponents,
            },
          })),
        );
      }
    };
  }

  public static addComponent(component: Component, target: Component) {
    return async (dispatch, getState: () => AppState) => {
      const { items } = getState().production.manageComponentsModal;

      const updatedArray = createComponentInEmptySlot(items, component, target);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          items: updatedArray,
        })),
      );
    };
  }

  public static createNew() {
    return async (dispatch, getState: () => AppState) => {
      const { product, variant, orderKey, targetSlot, configuration, additionalZoneId, externalOrderNumber } =
        getState().production.newComponentModal;

      let nestedProductionWorkflowItems = [];
      let slotId = '';

      if (targetSlot) {
        // eslint-disable-next-line prefer-destructuring
        slotId = targetSlot.id.split('_')[1];
        nestedProductionWorkflowItems = [{ id: slotId }];
      }

      const newComponent: Component = {
        ...EMPTY_SLOT,
        title: product.value.name,
        id: `new-${product.value.id}`,
        order: {
          ...EMPTY_SLOT.order,
          order_key: orderKey,
          external_order_number: externalOrderNumber,
        },
        variant: {
          ...EMPTY_SLOT.variant,
          name: variant.value.name,
        },
        configuration: {
          ...EMPTY_SLOT.configuration,
          name: configuration.value.name,
        },
        nestedProductionWorkflowItems,
      };

      if (additionalZoneId) {
        dispatch(ManageComponentsActions.addAdditionalComponents([newComponent], additionalZoneId));

        const logItem: AddedNewLogItem = {
          attached_to: additionalZoneId,
          components_to_add: [{ product_variant_id: variant.value.id }],
        };
        dispatch(ManageComponentsActions.addLogItem(logItem, LogType.AddedNew));
      } else if (targetSlot) {
        dispatch(ManageComponentsActions.addComponent(newComponent, targetSlot));

        const logItem: AddedNewLogItem = {
          create_plain: true,
          production_workflow_item_id_to_attach: slotId,
          attached_to: targetSlot.parent_production_workflow_id,
          components_to_add: [{ product_variant_id: variant.value.id }],
        };
        dispatch(ManageComponentsActions.addLogItem(logItem, LogType.AddedNew));
      }

      dispatch(NewComponentModalActions.closeModal());
    };
  }

  public static setMetaIdsOfActiveItem(metaIdsOfActiveItem: string[]) {
    return async (dispatch) => {
      dispatch(stateController.setState({ metaIdsOfActiveItem }));
    };
  }

  public static checkChangesInItems(activeItem: Component) {
    return async (dispatch, getState: () => AppState) => {
      const { logs } = getState().production.manageComponentsModal;
      const filteredLogs = filterUnusableLogs(logs.move, activeItem.id);
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          logs: {
            ...prev.logs,
            move: filteredLogs,
          },
        })),
      );
    };
  }
}

export class ManageComponentsSelectors {
  public static checkLogsEmpty(state: AppState) {
    const { move, addNew } = state.production.manageComponentsModal.logs;
    return !move.length && !addNew.length;
  }
}

export const ManageComponentsReducer = stateController.getReducer();
