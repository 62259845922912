import { useMemo } from 'react';
import ReactFlow, { Background, Edge } from 'reactflow';
import MinimapWithControls from 'components/ui-new/production-react-flow/components/minimap/minimap';
import { Nodes as ProductionWorkflowNodes } from 'pages/production-workflow/controllers/production-workflow.controller';
import 'reactflow/dist/style.css';
import CustomEdge from 'components/ui-new/production-react-flow/components/custom-edge/custom-edge';
import TaskNode from 'components/ui-new/production-react-flow/components/task-node/task-node';
import WorkflowNode from 'components/ui-new/production-react-flow/components/workflow-node/workflow-node';
import { Nodes as ManageFailedTasksNodes } from 'modules/manage-failed-tasks-modal/manage-failed-tasks.controller';

type Props = {
  edges: Edge[];
  isLoading: boolean;
  nodes: ProductionWorkflowNodes | ManageFailedTasksNodes;
  isToDoStatus?: boolean;
  isFullScreen?: boolean;
  setIsFullScreen?: (value: boolean) => void;
};

export const ProductionReactFlow = ({ nodes, edges, isLoading, isToDoStatus, isFullScreen, setIsFullScreen }: Props) => {
  const edgeTypes = useMemo(() => ({ smart: CustomEdge }), []);
  const nodeTypes = useMemo(() => ({ task: TaskNode, workflow: WorkflowNode }), []);

  return (
    <ReactFlow
      fitView
      panOnScroll
      maxZoom={1}
      minZoom={0.2}
      nodes={nodes}
      edges={edges}
      panOnDrag={[0, 2]}
      zoomOnScroll={false}
      edgeTypes={edgeTypes}
      nodeTypes={nodeTypes}
      zoomOnDoubleClick={false}
    >
      <Background />
      {!isToDoStatus && !isLoading && <MinimapWithControls isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} />}
    </ReactFlow>
  );
};

export default ProductionReactFlow;
