const KB_IN_MB = 1024 * 1024;
const GB_IN_MB = 1024;

export const VIDEO_FILE_MAX_SIZE = {
  mp4: 2 * GB_IN_MB * KB_IN_MB,
  mov: 2 * GB_IN_MB * KB_IN_MB,
};

export const IMAGE_FILE_MAX_SIZE = {
  jpg: 20 * KB_IN_MB,
  jpeg: 20 * KB_IN_MB,
  png: 20 * KB_IN_MB,
  webp: 20 * KB_IN_MB,
};

export const TEXT_FILE_MAX_SIZE = {
  txt: 20 * KB_IN_MB,
  doc: 20 * KB_IN_MB,
  docx: 20 * KB_IN_MB,
  pdf: 20 * KB_IN_MB,
  xlsx: 20 * KB_IN_MB,
  xls: 20 * KB_IN_MB,
};

// export const IMAGE_MAX_RESOLUTION = {
//   width: 4472,
//   height: 4472,
// };

export const FILES_MAX_COUNT = 250;
