import { FC } from 'react';
import Label from 'components/label/label';
import { Input } from 'components/ui-new/inputs/input';
import { TextFieldProps, Tooltip, TooltipProps } from '@mui/material';
import s from './input-modal-item.module.scss';

type Props = {
  title: string;
  placeholder: string;
  value: string;
  errorMessage: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  inputProps?: TextFieldProps['inputProps'];
  tooltipProps?: Omit<TooltipProps, 'children'>;
};

const InputModalItem: FC<Props> = (props) => {
  const { title, placeholder, value, errorMessage, onChange, disabled = false, inputProps, tooltipProps } = props;
  return (
    <div className={s.input_container}>
      <Label>{title}</Label>
      <Tooltip {...tooltipProps} componentsProps={{ tooltip: { sx: { maxWidth: 450 } } }}>
        <div>
          <Input
            size="small"
            error={!!errorMessage}
            placeholder={placeholder}
            fullWidth
            value={value ?? ''}
            disabled={disabled}
            inputProps={inputProps}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default InputModalItem;
