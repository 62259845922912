import { Menu } from '@mui/material';
import Button from 'components/button/button';
import { FC, useRef, useState, MouseEvent } from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { bindMenu, bindTrigger } from 'material-ui-popup-state/core';
import { AvatarCircle } from 'components/ui-new/avatar-circle/avatar-circle';
import { MultipleAssigneeSelectorSlotsT } from 'components/ui-new/multiple-assignee-selector/multiple-assignee-selector';
import s from './show-more-slots.module.scss';

export type OwnProps = {
  slots: MultipleAssigneeSelectorSlotsT[];
};

const ShowMoreSlots: FC<OwnProps> = ({ slots }) => {
  const [isOpen, setIsOpen] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);

  const popupState = usePopupState({ variant: 'popover', popupId: `actions-info` });

  const handleOnOpen = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen(true);
  };

  const handleOnClose = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  return (
    <div onClick={(event) => event.stopPropagation()} ref={rootRef} {...bindTrigger(popupState)}>
      <Button onClick={(e) => handleOnOpen(e)} className={s.other_users_container}>
        +{slots.length}
      </Button>

      <Menu
        {...bindMenu(popupState)}
        open={isOpen}
        anchorEl={rootRef.current}
        className={s.menu_container}
        anchorOrigin={{ vertical: -10, horizontal: 0 }}
        transformOrigin={{ vertical: 'bottom', horizontal: 0 }}
        onClose={(e: MouseEvent<HTMLButtonElement>) => handleOnClose(e)}
      >
        <div className={s.body_container}>
          {slots.map((item) => {
            const name = item.assignedUserId ? `${item.first_name || ''} ${item.last_name || ''}` : 'Unassigned';

            return (
              <div className={s.item_container} key={item.id}>
                <AvatarCircle
                  className={s.item_image}
                  lastName={item.last_name}
                  firstName={item.first_name}
                  isEmpty={!item.assignedUserId}
                  image={item.avatar_image_url}
                />
                <span className={s.item_title}>{name}</span>
              </div>
            );
          })}
        </div>
      </Menu>
    </div>
  );
};

export default ShowMoreSlots;
