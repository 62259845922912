import { FC, useState } from 'react';
import { EyeIcon } from 'icons/log-in-eye';
import { EyeSlashIcon } from 'icons/log-in-eye-slash';
import { Input as StyledInput } from 'components/ui-new/inputs/input';
import { IconButton, InputAdornment } from '@mui/material';
import s from './input.module.scss';

export type Props = {
  type: string;
  value: string;
  label?: string;
  name?: string;
  placeHolder?: string;
  error?: boolean;
  onChange: (value: string) => void;
};

const Input: FC<Props> = ({ type, label, name, value, placeHolder, error, onChange }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={s.wrapper}>
      <label htmlFor={label} className={s.label}>
        {label}
      </label>
      <StyledInput
        type={showPassword ? 'text' : type}
        id={label}
        className={s.input}
        name={name}
        value={value}
        placeholder={placeHolder}
        autoComplete="off"
        onChange={(e) => onChange(e.target.value)}
        error={error}
        InputProps={
          type === 'password' && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword((prev) => !prev)} edge="end">
                  {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }
        }
      />
    </div>
  );
};

export default Input;
