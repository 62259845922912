import { notify } from 'notifications';
import { Actions as ProductPreviewActions } from 'pages/product-flow/pages/product/controllers/product-preview.controller';
import { AppState } from 'redux/store';
import { ProductConfigurationService } from 'services/product-configurations.service';
import { ProductVariantService } from 'services/product-variant.service';
import { StateController } from 'state-controller';

export type RequestBody = {
  sku: string;
  variants: {
    sku?: string;
    barcode?: string;
    variant_id: string;
  }[];
};

export type VariantData = {
  sku?: string;
  barcode?: string;
};

export type VariantType = {
  sku: string;
  name: string;
  photo: string;
  barcode: string;
  variantId: string;
};

export type EditCodesModalState = {
  isLoading: boolean;
  isModalOpen: boolean;
  variants: VariantType[];
  configurationId: string;
  configurationSku: string;
  requestBody: RequestBody;
  initialData: RequestBody;
};

const defaultState: EditCodesModalState = {
  variants: [],
  isLoading: false,
  requestBody: null,
  initialData: null,
  isModalOpen: false,
  configurationId: '',
  configurationSku: '',
};

const stateController = new StateController<EditCodesModalState>('EDIT_CODES_MODAL', defaultState);

export class EditCodesActions {
  public static openModal(productConfigurationId: string) {
    return async (dispatch, getState: () => AppState) => {
      const {
        sku,
        id,
        product_variants: variants,
      } = getState().product.product_preview.product.product_configurations.find((c) => c.id === productConfigurationId);

      const variantsMapped = variants.map((item) => ({
        sku: item.sku,
        name: item.name,
        variantId: item.id,
        barcode: item.barcode,
        photo: item.variant_photo?.link || '',
      }));
      const initialVariants = variants.map((item) => ({
        sku: item.sku,
        variant_id: item.id,
        barcode: item.barcode,
      }));

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isModalOpen: true,
          configurationId: id,
          configurationSku: sku,
          variants: variantsMapped,
          initialData: {
            ...prev.initialData,
            sku,
            variants: initialVariants,
          },
        })),
      );
    };
  }

  public static closeModal() {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState({ isModalOpen: false }));
      setTimeout(() => dispatch(stateController.setState({ ...defaultState })), 100);

      if (EditCodesSelectors.isFieldsEdited(getState())) {
        const id = getState().product.edit_codes_modal.configurationId;
        const { initialData } = getState().product.edit_codes_modal;
        await ProductConfigurationService.updateCodes(id, initialData);
      }
    };
  }

  public static saveChanges() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));

        const body = getState().product.edit_codes_modal.requestBody;
        const id = getState().product.edit_codes_modal.configurationId;
        const productId = getState().product.product_preview.product.id;

        await ProductConfigurationService.updateCodes(id, body);
        await dispatch(ProductPreviewActions.open(productId));

        dispatch(stateController.setState({ isModalOpen: false }));
        setTimeout(() => dispatch(stateController.setState({ ...defaultState })), 100);

        notify.success('Updated successful');
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static setConfigurationSku(value: string) {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState({ configurationSku: value }));
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          requestBody: {
            ...prev.requestBody,
            sku: value,
          },
        })),
      );

      const body = getState().product.edit_codes_modal.requestBody;
      const id = getState().product.edit_codes_modal.configurationId;

      await ProductConfigurationService.updateCodes(id, body);
      const data = await ProductVariantService.getAll(id);
      const variantsMapped = data.map((i) => ({
        sku: i.sku,
        name: i.name,
        variantId: i.id,
        barcode: i.barcode,
        photo: i.variant_photo?.link,
      }));

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          variants: variantsMapped,
        })),
      );
    };
  }

  public static onChangeVariant(id: string, value: VariantData) {
    return async (dispatch, getState: () => AppState) => {
      const { variants } = getState().product.edit_codes_modal;
      const targetVariant = variants.find((item) => item.variantId === id);

      if (targetVariant) {
        const updatedVariant = {
          ...targetVariant,
          ...value,
        };
        const updatedVariants = variants.map((item) => (item.variantId === id ? updatedVariant : item));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            variants: updatedVariants,
          })),
        );
        dispatch(EditCodesActions.changeRequestBody(id, value));
      }
    };
  }

  public static changeRequestBody(id: string, value: VariantData) {
    return async (dispatch, getState: () => AppState) => {
      const variants = getState().product.edit_codes_modal.requestBody?.variants || [];
      const targetVariant = variants.find((item) => item?.variant_id === id);
      if (targetVariant) {
        const updatedVariant = {
          ...targetVariant,
          ...value,
        };
        const updatedVariants = variants.map((item) => (item.variant_id === id ? updatedVariant : item));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            requestBody: {
              ...prev.requestBody,
              variants: updatedVariants,
            },
          })),
        );
      } else {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            requestBody: {
              ...prev.requestBody,
              variants: [...variants, { variant_id: id, ...value }],
            },
          })),
        );
      }
    };
  }
}

export class EditCodesSelectors {
  public static isFieldsEdited(state: AppState) {
    const isSku = Boolean(state.product.edit_codes_modal.requestBody?.sku);
    const isVariants = Boolean(state.product.edit_codes_modal.requestBody?.variants);
    return isVariants || isSku;
  }
}

export const reducer = stateController.getReducer();
