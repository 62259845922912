import { WarningIcon } from 'icons/warning';
import s from './max-letters-warning.module.scss';

export default function MaxLettersWarning({ max = 255 }) {
  return (
    <div className={s.warning_container}>
      <WarningIcon className={s.warning_icon} />
      <span className={s.warning_title}>{`Maximum character limit ${max}. Please, shorten the text to the allowable size.`}</span>
    </div>
  );
}
