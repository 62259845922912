import { AppState } from 'redux/store';
import { StateController } from 'state-controller';
import { PriorityEnum } from 'types/priority-enums';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { ProductionWorkflow, ProductionWorkflowInfo } from 'services/production-workflow.model';

export type ManageTaskPriorityModalArgs = {
  production: ProductionWorkflow | ProductionWorkflowInfo;
};

export type ManageTaskPriorityModalState = {
  isOpen: boolean;
  isLoading: boolean;
  isChecked: boolean;
  isShowCheckbox: boolean;
  id: string;
  productName: string;
  productionKey: string;
  value: PriorityEnum;
};

const defaultState: ManageTaskPriorityModalState = {
  isOpen: false,
  isLoading: false,
  isChecked: false,
  isShowCheckbox: false,
  id: '',
  productName: '',
  productionKey: '',
  value: PriorityEnum.Medium,
};

const stateController = new StateController<ManageTaskPriorityModalState>('MANAGE_TASK_PRIORITY_MODAL', defaultState);

export class ManageTaskPriorityModalActions {
  public static openModal({ production }: ManageTaskPriorityModalArgs) {
    return async (dispatch) => {
      const hasAddittionalComponents = production.additionalComponents.length > 0;
      const hasNestedComponents = production.productionWorkflowItems.some(
        (i) => typeof i.nested_production_workflow_id === 'string',
      );
      const isShowCheckbox = hasAddittionalComponents || hasNestedComponents;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isOpen: true,
          isShowCheckbox,
          id: production.id,
          productName: production.product_name,
          productionKey: production.production_key,
        })),
      );
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
      setTimeout(() => dispatch(stateController.setState({ ...defaultState })), 100);
    };
  }

  public static onSave() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const { id, value, isChecked } = getState().production.manageTaskPriorityModal;
        const requestBody = {
          priority: value,
          apply_changes_to_components: isChecked,
        };
        await ProductionWorkflowService.manageTaskPriority(id, requestBody);
        dispatch(ManageTaskPriorityModalActions.closeModal());
      } catch (err) {
        dispatch(stateController.setState({ isLoading: false }));
        throw err;
      }
    };
  }

  public static onChangeCheckbox(value: boolean) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isChecked: value }));
    };
  }

  public static onChangePriority(value: PriorityEnum) {
    return async (dispatch) => {
      dispatch(stateController.setState({ value }));
    };
  }
}

export const manageTaskPriorityModalReducer = stateController.getReducer();
