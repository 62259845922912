import { v4 as uuidv4 } from 'uuid';
import { notify } from 'notifications';
import { IdName } from 'types/common-types';
import { StateController } from 'state-controller';
import { AppState, GetStateFunction } from 'redux/store';
import { AccessLevel, Permission } from 'services/permission.model';
import { removeNewIdFromObject } from 'utils/remove-id-from-object';
import { ProductOptionsService } from 'services/product-options.service';
import { TaskTemplateBonus } from 'services/workflow-task-template.model';
import { BonusOptions, BonusType } from 'services/workflow-task-template-bonuses.model';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { WorkflowTemplateBonusesService } from 'services/workflow-task-template-bonuses.service';
import { Actions as WorkflowTaskTemplateActions } from 'pages/product-flow/pages/workflow-task-template/workflow-task-template.controller';
import { ProductsService } from 'services/products.service';

export type BonusOption = IdName & { product_option_values: IdName[] };

export type TaskTemplateBonuses = {
  id?: string;
  product_option: IdName;
  product_option_value: IdName;
  type: string;
  value: number;
};

export type FieldsValidation = {
  bonusField: string | null;
  combineField: string | null;
};

export type BonusesModalState = {
  isOpen: boolean;
  isLoading: boolean;
  isEditMode?: boolean;
  didTryToSave?: boolean;
  bonusOptions: BonusOption[];
  selectedBonuses: TaskTemplateBonus;
  combinationBonuses: BonusOptions[];
  fieldsValidation: FieldsValidation;
};

const defaultState: BonusesModalState = {
  isOpen: false,
  isLoading: false,
  isEditMode: false,
  didTryToSave: false,
  bonusOptions: [],
  selectedBonuses: {
    parameter_bonuses: [],
    combination_bonuses: [],
  },
  combinationBonuses: [],
  fieldsValidation: {
    bonusField: null,
    combineField: null,
  },
};

const stateController = new StateController<BonusesModalState>('WORKFLOW_TASK_TEMPLATE_BONUSES_MODAL', defaultState);

export class Actions {
  public static openCreateBonusesModal() {
    return async (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { breadcrumbs } = getState().workflow_task_template.workflowTaskTemplate;
      const configurationId = breadcrumbs.find((item) => item.type === 'configuration').id;
      const bonusOptions = await ProductOptionsService.getAll(undefined, configurationId);

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          isOpen: true,
          isEditMode: false,
          bonusOptions,
        })),
      );
    };
  }

  public static openUpdateBonusesModal() {
    return async (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { bonuses, breadcrumbs } = getState().workflow_task_template.workflowTaskTemplate;
      const configurationId = breadcrumbs.find((item) => item.type === 'configuration').id;
      const bonusOptions = await ProductOptionsService.getAll(undefined, configurationId);

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          isOpen: true,
          isEditMode: true,
          bonusOptions,
          selectedBonuses: bonuses,
        })),
      );
    };
  }

  public static closeModal() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static onChangeCombinationSelect(combination: BonusOptions) {
    return (dispatch, getState: GetStateFunction) => {
      const { combinationBonuses } = getState().workflow_task_template_bonuses_modal;
      const selectedOption = combinationBonuses.some((item) => item.product_option.id === combination.product_option.id);
      const changedCombinationValue = combinationBonuses.map((item) => {
        return item.product_option.id === combination.product_option.id ? combination : item;
      });

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          combinationBonuses: selectedOption ? changedCombinationValue : [...prev.combinationBonuses, combination],
        })),
      );
    };
  }

  public static setSelectedBonus(option: Partial<BonusOptions>) {
    return async (dispatch, getState: GetStateFunction) => {
      const { selectedBonuses } = getState().workflow_task_template_bonuses_modal;
      const { workflowTaskTemplate } = getState().workflow_task_template;
      const isValueChosen = selectedBonuses.parameter_bonuses.some((parameter) => {
        return parameter.product_option_combinations.some(
          (item) => item.product_option_value.id === option.product_option_value.id,
        );
      });

      if (isValueChosen) {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            selectedBonuses: {
              ...prevState.selectedBonuses,
              parameter_bonuses: prevState.selectedBonuses.parameter_bonuses.filter(
                (item) => item.product_option_combinations[0].product_option_value.id !== option.product_option_value.id,
              ),
            },
          })),
        );
        dispatch(Actions.bonusFieldValidation());
        return;
      }

      const temporaryId = uuidv4();
      const newParametersBonus = {
        id: `new-${temporaryId}`,
        product_option_combinations: [option],
        task_template_id: workflowTaskTemplate.id,
        type: BonusType.Percentage,
        value: 0,
      };

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedBonuses: {
            ...prev.selectedBonuses,
            parameter_bonuses: [...prev.selectedBonuses.parameter_bonuses, newParametersBonus],
          },
        })),
      );
      dispatch(Actions.bonusFieldValidation());
    };
  }

  public static setSelectedCombinationBonus() {
    return async (dispatch, getState: GetStateFunction) => {
      const { selectedBonuses, combinationBonuses } = getState().workflow_task_template_bonuses_modal;
      const { workflowTaskTemplate } = getState().workflow_task_template;

      const isValueChosen = selectedBonuses.combination_bonuses.some(
        (parameter) =>
          parameter?.product_option_combinations?.length === combinationBonuses.length &&
          parameter?.product_option_combinations
            .map((option) => option?.product_option_value?.name)
            .sort()
            .join() ===
            combinationBonuses
              .map((option) => option?.product_option_value?.name)
              .sort()
              .join(),
      );

      if (isValueChosen) {
        notify.error('This combination already exist');
        return;
      }
      const temporaryId = uuidv4();
      const newCombinationBonus = {
        task_template_id: workflowTaskTemplate.id,
        id: `new-${temporaryId}`,
        type: BonusType.Percentage,
        value: 0,
        product_option_combinations: combinationBonuses,
      };

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedBonuses: {
            ...prev.selectedBonuses,
            combination_bonuses: [...prev.selectedBonuses.combination_bonuses, newCombinationBonus],
          },
          combinationBonuses: [],
        })),
      );
      dispatch(Actions.bonusFieldValidation());
    };
  }

  public static createBonuses() {
    return async (dispatch, getState: GetStateFunction) => {
      const { workflowTaskTemplate } = getState().workflow_task_template;
      const { selectedBonuses, fieldsValidation } = getState().workflow_task_template_bonuses_modal;

      try {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            isLoading: true,
            didTryToSave: true,
          })),
        );
        if (Object.values(fieldsValidation).every((warning) => warning === null)) {
          const createResult = await WorkflowTemplateBonusesService.create([
            ...selectedBonuses.combination_bonuses,
            ...selectedBonuses.parameter_bonuses,
          ]);

          await ProductsService.updateProduct(workflowTaskTemplate.product.id);

          dispatch(WorkflowTaskTemplateActions.updateTemplateOptions(createResult));
          dispatch(Actions.closeModal());
        }
      } catch (error) {
        dispatch(WorkflowTaskTemplateActions.updateTemplateOptions(workflowTaskTemplate.bonuses));
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static manageBonuses() {
    return async (dispatch, getState: GetStateFunction) => {
      const { workflowTaskTemplate } = getState().workflow_task_template;
      const { selectedBonuses, fieldsValidation } = getState().workflow_task_template_bonuses_modal;

      const mappedParams = removeNewIdFromObject(selectedBonuses.parameter_bonuses);
      const mappedCombination = removeNewIdFromObject(selectedBonuses.combination_bonuses);

      try {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            isLoading: true,
            didTryToSave: true,
          })),
        );

        if (Object.values(fieldsValidation).every((warning) => warning === null)) {
          dispatch(WorkflowTaskTemplateActions.updateTemplateOptions(selectedBonuses));
          const taskTemplateBonus = await WorkflowTemplateBonusesService.manage({
            task_template_id: workflowTaskTemplate.id,
            values: [...mappedCombination, ...mappedParams],
          });

          await ProductsService.updateProduct(workflowTaskTemplate.product.id);

          dispatch(WorkflowTaskTemplateActions.updateTemplateOptions(taskTemplateBonus));
          dispatch(Actions.closeModal());
        }
      } catch (error) {
        dispatch(WorkflowTaskTemplateActions.updateTemplateOptions(workflowTaskTemplate.bonuses));
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static setParameterValues(optionValueId: string, values: Partial<TaskTemplateBonuses>) {
    return (dispatch) => {
      if (values.value) {
        if (values.value.toString().split('.')[1]?.length > 2) return;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedBonuses: {
            ...prev.selectedBonuses,
            combination_bonuses: prev.selectedBonuses.combination_bonuses.map((parameter) => {
              return parameter.id === optionValueId ? { ...parameter, ...values } : parameter;
            }),
            parameter_bonuses: prev.selectedBonuses.parameter_bonuses.map((parameter) => {
              return parameter.id === optionValueId ? { ...parameter, ...values } : parameter;
            }),
          },
          combinationBonuses: [],
        })),
      );
      dispatch(Actions.bonusFieldValidation());
    };
  }

  public static removeParameter(id: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { selectedBonuses } = getState().workflow_task_template_bonuses_modal;

      const filteredParameters = selectedBonuses.parameter_bonuses.filter((parameter) => parameter.id !== id);
      const filteredCombination = selectedBonuses.combination_bonuses.filter((parameter) => parameter.id !== id);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedBonuses: {
            ...prev.selectedBonuses,
            combination_bonuses: filteredCombination,
            parameter_bonuses: filteredParameters,
          },
          combinationBonuses: [],
        })),
      );
      dispatch(Actions.bonusFieldValidation());
    };
  }

  public static bonusFieldValidation() {
    return (dispatch, getState: GetStateFunction) => {
      const { selectedBonuses } = getState().workflow_task_template_bonuses_modal;
      const fieldsValidated = {};
      if (selectedBonuses.combination_bonuses && selectedBonuses.combination_bonuses.length > 0) {
        selectedBonuses.combination_bonuses.forEach((combination, index) => {
          if (!combination.value) {
            fieldsValidated[`valueCombination${index}`] = `Combination value ${index + 1} should not be less than zero`;
          }
        });
      }
      if (selectedBonuses.parameter_bonuses && selectedBonuses.parameter_bonuses.length > 0) {
        selectedBonuses.parameter_bonuses.forEach((bonus, index) => {
          if (!bonus.value) {
            fieldsValidated[`valueBonus${index}`] = `Bonus value ${index + 1} should not be less than zero`;
          }
        });
      }

      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          fieldsValidation: fieldsValidated,
        })),
      );
    };
  }
}

export class Selectors {
  public static isHadWarnings(state: AppState) {
    const { fieldsValidation } = state.workflow_task_template_bonuses_modal;
    return Object.values(fieldsValidation).every((warning) => warning === null);
  }

  public static filteredWarnings(state: AppState) {
    const { fieldsValidation } = state.workflow_task_template_bonuses_modal;
    return Object.values(fieldsValidation).filter((warning) => warning !== null);
  }
}

export const reducer = stateController.getReducer();
