import { useCallback, useEffect, useMemo, useRef, useState, CSSProperties } from 'react';
import { connect } from 'react-redux';
import ModalSvgWrapper from 'icons/modal-svg-wrapper/svg-wrapper';
import { BlockIcon } from 'icons/block';
import Button from 'components/button/button';
import { AppState } from '../../redux/store';
import Modal from '../../components/ui-new/modal/modal';
import { PermissionGuardActions } from './permission-guard.controller';
import styles from './permission-guard.module.scss';

const defaultPositonState = { top: 0, left: 0, transform: 'translate(0, 0)' };

const isClickContainsElement = (event, ref) => {
  if (ref.current.contains(event.target)) {
    return true;
  }
  return false;
};

type ModalPositionResult = {
  top: number;
  left: number;
  transform: string;
};
const getModalPosition = (event): ModalPositionResult => {
  const top = event.clientY;
  const left = event.clientX;
  const viewportHeight = window.innerHeight;
  const viewportWidth = window.innerWidth;
  const halfOfHeigth = viewportHeight / 2;
  const halfOfWidth = viewportWidth / 2;

  let transform;

  // Top Left square of the viewport
  if (left < halfOfWidth && top < halfOfHeigth) {
    transform = 'translate(10%, 10%)';
  }
  // Top Right square of the viewport
  if (left > halfOfWidth && top < halfOfHeigth) {
    transform = 'translate(-110%, 10%)';
  }
  // Bottom Right square of the viewport
  if (left > halfOfWidth && top > halfOfHeigth) {
    transform = 'translate(-110%, -110%)';
  }
  // Bottom Left square of the viewport
  if (left < halfOfWidth && top > halfOfHeigth) {
    transform = 'translate(10%, -110%)';
  }

  return {
    top,
    left,
    transform,
  };
};

type StateProps = {
  isOpen: boolean;
};
type DispatchProps = {
  closeModal: () => void;
};
type Props = StateProps & DispatchProps;

function PermissionGuardModal({ isOpen, closeModal }: Props) {
  const modalRef = useRef(null);
  const [position, setModalPosition] = useState<ModalPositionResult>(defaultPositonState);

  const handleMouseClick = useCallback(
    (event) => {
      if (isOpen && !isClickContainsElement(event, modalRef)) {
        const pos = getModalPosition(event);
        setModalPosition(pos);
      }
    },
    [isOpen],
  );

  useEffect(() => {
    document.addEventListener('click', handleMouseClick);
    return () => {
      document.removeEventListener('click', handleMouseClick);
    };
  }, [handleMouseClick]);

  const modalStyles = useMemo<CSSProperties>(
    () => ({
      position: 'fixed',
      top: position.top,
      left: position.left,
      transform: position.transform,
      overflow: 'hidden',
      width: '360px',
    }),
    [position],
  );

  return (
    <Modal
      key="permission-guard-modal"
      open={isOpen}
      onClose={closeModal}
      PaperProps={{ className: styles.modal, style: modalStyles, ref: modalRef }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      <div className={styles.body_container}>
        <div className={styles.header_container}>
          <ModalSvgWrapper backgroundColor="#DBE9FF" iconSize={20} size="newMedium" SvgIcon={BlockIcon} />
          <div className={styles.title_container}>
            <span className={styles.title}>Insufficient rights to access this feature.</span>
            <span className={styles.title}>Please contact your HESH administrator</span>
          </div>
        </div>
        <div>
          <Button className={styles.button} fullWidth size="S" variant="contained" color="primary" onClick={closeModal}>
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state: AppState): StateProps => ({
  isOpen: state.permissionGuard.isOpen,
});
const mapDispatchToProps: DispatchProps = {
  closeModal: PermissionGuardActions.closeModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(PermissionGuardModal);
