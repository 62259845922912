import { notify } from 'notifications';
import { AppState } from 'redux/store';
import {
  CreateProductCategoryData,
  ProductCategory,
  ProductCategoryChild,
  ProductCategoryWithChildren,
  UpdateProductCategoryData,
} from 'services/product-categories.model';
import { AccessLevel, Permission } from 'services/permission.model';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { ProductCategoriesService } from 'services/product-categories.service';
import { Actions as ProductsModalsActions, ProductsModalsNameEnum } from 'pages/products/products-modals.controller';
import { Actions as ProductsActions } from 'pages/products/products.controller';
import { t } from 'setup-localization';
import { StateController } from 'state-controller';

export const NEW_CATEGORY_ID = 'new';

export type ProductsCategoriesState = {
  activeCategory: ProductCategoryWithChildren;
  items: ProductCategoryChild[];
  allCategories: ProductCategory[];
  isLoading: boolean;
  isProcessing: boolean;
  isLoadingAllCategories: boolean;
  renameModeItemId: string;
  lastAddedCategoryId: string;
};

const defaultState: ProductsCategoriesState = {
  isLoading: false,
  isProcessing: false,
  activeCategory: null,
  items: [],
  allCategories: [],
  isLoadingAllCategories: false,
  renameModeItemId: '',
  lastAddedCategoryId: '',
};

const stateController = new StateController<ProductsCategoriesState>('PRODUCTS_CATEGORIES', defaultState);

export class Actions {
  public static init(categoryId: string | null) {
    return async (dispatch) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        dispatch(ProductsActions.setIsLoading(true));

        const category = await dispatch(Actions.silentLoadProductsCategories(categoryId));
        await dispatch(ProductsActions.init(category.id));

        dispatch(Actions.silentLoadAllCategories());
      } finally {
        dispatch(ProductsActions.setIsLoading(false));
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static silentLoadProductsCategories(categoryId: string | null) {
    return async (dispatch) => {
      const category = await ProductCategoriesService.getProductCategoriesWithChildren(categoryId);
      dispatch(stateController.setState((prev) => ({ ...prev, activeCategory: category, items: category.categories })));
      return category;
    };
  }

  public static loadAllCategories() {
    return async (dispatch) => {
      try {
        dispatch(stateController.setState({ isLoadingAllCategories: true }));
        await dispatch(Actions.silentLoadAllCategories());
      } finally {
        dispatch(stateController.setState({ isLoadingAllCategories: false }));
      }
    };
  }

  public static silentLoadAllCategories() {
    return async (dispatch) => {
      const categories = (await ProductCategoriesService.getProductCategories()).data;
      dispatch(stateController.setState({ allCategories: categories }));
      return true;
    };
  }

  public static renameCategory(id?: string, name?: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));

        const categoryId = id || getState().products.productsModals.renameModal.entityId;
        const parentId = getState().products.categories.activeCategory.id;
        const newName = name || getState().products.productsModals.renameModal.data.name;

        const data: UpdateProductCategoryData = {
          name: newName,
          parent_id: parentId,
        };

        await ProductCategoriesService.updateProductCategory(categoryId, data);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            items: prev.items.map((item) => (item.id !== categoryId ? item : { ...item, name: newName })),
            renameModeItemId: '',
          })),
        );

        notify.success('Successfully renamed');
      } catch (error) {
        notify.error(<>{t('product_flow.notification.update_category_error')}</>);
        throw error;
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static createNewCategory() {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(
        Actions.addCategoryToList({
          id: 'new',
          name: '',
          productsCount: 0,
          types: [],
          tags: [],
          subcategories: [],
        }),
      );
      setTimeout(() => {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            renameModeItemId: 'new',
          })),
        );
      }, 0);
    };
  }

  public static removeNewCategoryFromList() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          renameModeItemId: '',
        })),
      );
      dispatch(Actions.removeCategoryFromList(NEW_CATEGORY_ID));
    };
  }

  public static renameMode(entityId: string) {
    return async (dispatch, getState: () => AppState) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const categories = getState().products.categories.items;
      const currentProductId = categories.find((i) => i.id === entityId).id;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          renameModeItemId: currentProductId,
        })),
      );
    };
  }

  public static renameModeIsClosed() {
    return async (dispatch, getState: () => AppState) => {
      const ItemId = getState().products.categories.renameModeItemId;
      if (!ItemId) return;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          renameModeItemId: '',
        })),
      );
    };
  }

  public static setLastAddedCategoryId(lastAddedCategoryId: string) {
    return async (dispatch) => {
      dispatch(stateController.setState({ lastAddedCategoryId }));
      setTimeout(() => dispatch(stateController.setState({ lastAddedCategoryId: '' })), 2000);
    };
  }

  public static createCategoryWithoutModal(categoryName?: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const categoryId = getState().products.categories.activeCategory.id;
        let data: CreateProductCategoryData = {} as CreateProductCategoryData;

        data = {
          name: categoryName,
          parent_id: categoryId,
        };

        const { id } = await ProductCategoriesService.createProductCategory(data);

        await dispatch(Actions.silentLoadProductsCategories(categoryId));
        dispatch(Actions.renameModeIsClosed());
        dispatch(Actions.setLastAddedCategoryId(id));

        notify.success('Successfully created');
      } catch (error) {
        notify.error(<>{t('product_flow.notification.create_category_error')}</>);
        throw error;
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static createCategory() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));

        const categoryId = getState().products.categories.activeCategory.id;

        const data: CreateProductCategoryData = {} as CreateProductCategoryData;

        const {
          data: { name },
        } = getState().products.productsModals.addEntityModal;

        data.name = name;
        data.parent_id = categoryId;

        const newCategory = await ProductCategoriesService.createProductCategory(data);
        dispatch(
          Actions.addCategoryToList({
            id: newCategory.id,
            name: newCategory.name,
            productsCount: 0,
            types: [],
            tags: [],
            subcategories: [],
          }),
        );
        dispatch(ProductsModalsActions.closeModal(ProductsModalsNameEnum.AddEntity));
        dispatch(Actions.silentLoadProductsCategories(categoryId));

        notify.success(<>{t('product_flow.notification.create_category_success')}</>);
      } catch (error) {
        notify.error(<>{t('product_flow.notification.create_category_error')}</>);
        throw error;
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static removeCategory(id: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));

        const categoryId = Selectors.selectCategoryId(getState());

        await ProductCategoriesService.deleteProductCategory(id);
        dispatch(Actions.removeCategoryFromList(id));
        dispatch(Actions.silentLoadProductsCategories(categoryId));
        notify.success('Successfully deleted');
      } catch (error) {
        notify.error(<>{t('product_flow.notification.delete_category_error')}</>);
        throw error;
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static onCategoriesIsActiveChange(categoriesId: string, isCategoriesActive: boolean) {
    return async () => {
      try {
        await ProductCategoriesService.changeIsActive(categoriesId, { is_active: isCategoriesActive });
        if (!isCategoriesActive) {
          notify.success('Category inactivated successfully');
        } else {
          notify.success('Category activated successfully');
        }
      } catch (error) {
        notify.error('Something went wrong');
        throw error;
      }
    };
  }

  public static moveCategory() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));
        const categoryId = getState().products.productsModals.moveModal.entityId;
        const categoryName = getState().products.categories.items.find((category) => category.id === categoryId).name;
        const newParentId = getState().products.productsModals.moveModal.data.value.id;

        const data = {
          name: categoryName,
          parent_id: newParentId,
        };
        await ProductCategoriesService.updateProductCategory(categoryId, data);

        dispatch(Actions.removeCategoryFromList(categoryId));
        dispatch(Actions.init(getState().products.categories.activeCategory.id));

        notify.success('Successfully moved');
      } catch (error) {
        notify.error('Something went wrong');
        throw error;
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static addCategoryToList(category: ProductCategoryChild) {
    return async (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, items: [category, ...prev.items] })));
    };
  }

  public static removeCategoryFromList(categoryId: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          items: prev.items.filter((item) => item.id !== categoryId),
        })),
      );
    };
  }

  public static removeSelectedCategoriesFromList(selectedIds: string[]) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          items: prev.items.filter((item) => !selectedIds.includes(item.id)),
        })),
      );
    };
  }
}

export class Selectors {
  public static selectCategoryId(state: AppState) {
    return state.products.categories.activeCategory.id;
  }
}

export const reducer = stateController.getReducer();
