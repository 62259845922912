import { StateController } from 'state-controller';
import { ProductsService } from 'services/products.service';
import { router } from 'index';
import { EntityType } from 'types/common-enums';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { Actions as MultiselectActions } from 'pages/products/multiselect.controller';
import { CreateProductData, ProductData, ProductDataPublishedProducts, UpdateProductMeta } from 'services/products.model';
import { notify } from 'notifications';
import { AccessLevel, Permission } from 'services/permission.model';
import { Actions as ProductsModalsActions, ProductsModalsNameEnum } from 'pages/products/products-modals.controller';
import { Actions as ProductsCategoryActions } from 'pages/products/products-categories.controller';
import { Actions as ProductSearchActions } from 'pages/product-search/product-search.controller';
import { t } from 'setup-localization';
import { AppState } from 'redux/store';
import { ProductCategoriesService } from 'services/product-categories.service';
import { ProductTagsService } from 'services/product-tags.service';
import { ManageProductTagsData, Tag } from 'services/product-tags.model';

export const NEW_PRODUCT_ID = 'new';

export type IdNameType = {
  id: string;
  name: string;
  type: EntityType;
};

export type QuickSearchState = {
  keyword: string;
  items: IdNameType[];
  isFetching: boolean;
};

export type ProductsState = {
  isLoading: boolean;
  isProcessing: boolean;
  items: ProductDataPublishedProducts[];
  tags: Tag[];
  isLoadingTags: boolean;
  renameModeItemId: string;
  lastAddedProductId: string;

  quickSearch: QuickSearchState;
};

const defaultState: ProductsState = {
  isLoading: true,
  isProcessing: false,
  items: [],
  tags: [],
  isLoadingTags: false,
  renameModeItemId: '',
  lastAddedProductId: '',

  quickSearch: {
    keyword: '',
    items: [],
    isFetching: false,
  },
};

const stateController = new StateController<ProductsState>('ALL_PRODUCTS', defaultState);

export class Actions {
  public static init(categoryId: string | null) {
    return async (dispatch) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        await dispatch(Actions.silentLoadProducts(categoryId));
        dispatch(Actions.loadTags());
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static silentLoadProducts(categoryId: string | null) {
    return async (dispatch) => {
      const products = await ProductsService.getProducts({ product_category_id: categoryId });
      dispatch(stateController.setState({ items: products }));
      return true;
    };
  }

  public static loadTags() {
    return async (dispatch) => {
      try {
        dispatch(stateController.setState({ isLoadingTags: true }));
        const tags = await ProductTagsService.getAllTags();
        dispatch(stateController.setState({ tags }));
      } finally {
        dispatch(stateController.setState({ isLoadingTags: false }));
      }
    };
  }

  public static removeNewProductFromList() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          renameModeItemId: '',
        })),
      );
      dispatch(Actions.removeProductFromList(NEW_PRODUCT_ID));
    };
  }

  public static createNewProduct() {
    return async (dispatch, getState: () => AppState) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { id, name } = getState().products.categories.activeCategory;

      dispatch(
        Actions.addProductToList({
          id: NEW_PRODUCT_ID,
          modified_at: '',
          modified_by: 'new',
          is_active: true,
          product_meta: {
            id: NEW_PRODUCT_ID,
            name: '',
            product_category: { id, name },
            product_type: null,
            product_vendor: null,
            product_tag_relations: [],
          },
          configurations: 1,
          variants: 1,
          workFlows: 0,
          tags: [],
          category_path: [],
          is_draft: true,
          is_published: false,
          version: '',
          published_products: 0,
        }),
      );
      setTimeout(() => {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            renameModeItemId: 'new',
          })),
        );
      }, 0);
    };
  }

  public static setLastAddedProductId(lastAddedProductId: string) {
    return async (dispatch) => {
      await dispatch(stateController.setState({ lastAddedProductId }));
      setTimeout(() => dispatch(stateController.setState({ lastAddedProductId: '' })), 2000);
    };
  }

  public static renameMode(entityId: string) {
    return async (dispatch, getState: () => AppState) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const products = getState().products.products.items;
      const currentProductId = products.find((i) => i.product_meta.id === entityId).product_meta.id;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          renameModeItemId: currentProductId,
        })),
      );
    };
  }

  public static renameModeIsClosed() {
    return async (dispatch, getState: () => AppState) => {
      const ItemId = getState().products.products.renameModeItemId;
      if (!ItemId) return;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          renameModeItemId: '',
        })),
      );
    };
  }

  public static createProduct(name: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));

        const categoryId = getState().products.categories.activeCategory.id;

        let data: CreateProductData = {} as CreateProductData;

        data = {
          name,
          product_category_id: categoryId,
        };

        const { id } = await ProductsService.createProduct(data);

        await dispatch(Actions.silentLoadProducts(categoryId));
        dispatch(Actions.renameModeIsClosed());
        dispatch(Actions.setLastAddedProductId(id));

        notify.success('Successfully created');
      } catch (error) {
        notify.error(<>{t('product_flow.notification.create_product_error')}</>);
        throw error;
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static updateProduct(id: string, data: UpdateProductMeta) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));

        const originalProduct = getState().products.products.items.find((item) => item.product_meta.id === id).product_meta;

        const newData: UpdateProductMeta = { ...originalProduct, ...data };

        const newProduct = await ProductsService.updateProductMeta(id, newData);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            items: prev.items.map((item) => {
              return item.product_meta.id !== id
                ? item
                : {
                    ...item,
                    product_meta: {
                      ...newProduct,
                    },
                  };
            }),
          })),
        );

        dispatch(Actions.renameModeIsClosed());
        notify.success('Successfully renamed');
      } catch (error) {
        notify.error(<>{t('product_flow.notification.update_product_error')}</>);
        throw error;
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static removeProduct(id: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));

        await ProductsService.deleteProduct(id);
        dispatch(Actions.removeProductFromList(id));
        dispatch(Actions.silentLoadProducts(getState().products.categories.activeCategory.id));

        notify.success('Successfully deleted');
      } catch (error) {
        notify.error('Something went wrong');
        throw error;
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static moveProduct() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));
        const productId = getState().products.productsModals.moveModal.entityId;
        const newParentId = getState().products.productsModals.moveModal.data.value.id;
        const { product_meta } = getState().products.products.items.find((item) => item.id === productId);

        const newProduct = {
          product_category_id: newParentId,
        };

        await ProductsService.updateProductMeta(product_meta.id, newProduct);

        dispatch(Actions.removeProductFromList(productId));
        dispatch(ProductsCategoryActions.init(getState().products.categories.activeCategory.id));

        notify.success('Successfully moved');
      } catch (error) {
        notify.error(<>{t('product_flow.notification.update_product_error')}</>);
        throw error;
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static moveSelectedItems() {
    return async (dispatch, getState: () => AppState) => {
      const { selectedProductIds } = getState().products.multiselect;
      const { selectedCategoryIds } = getState().products.multiselect;
      const activeCategoryId = getState().products.categories.activeCategory.id;
      const targetCategoryId = getState().products.productsModals.moveModal.data.value.id;

      try {
        if (selectedProductIds.length !== 0) {
          await ProductsService.updateParentCategory(selectedProductIds, targetCategoryId);
          dispatch(Actions.removeSelectedProductsFromList(selectedProductIds));
        }
        if (selectedCategoryIds.length !== 0) {
          await ProductCategoriesService.updateParentCategory(selectedCategoryIds, targetCategoryId);
          dispatch(ProductsCategoryActions.removeSelectedCategoriesFromList(selectedCategoryIds));
        }

        dispatch(Actions.silentLoadProducts(activeCategoryId));
        dispatch(ProductsCategoryActions.silentLoadAllCategories());
        dispatch(MultiselectActions.deselectAll());

        notify.success('Successfully moved');
      } catch (error) {
        notify.error(<>{t('product_flow.notification.update_product_error')}</>);
        throw error;
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static removeSelectedProductsFromList(selectedIds: string[]) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          items: prev.items.filter((item) => !selectedIds.includes(item.id)),
        })),
      );
    };
  }

  public static manageProductTags(productTags: Tag[]) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { entityId } = getState().products.productsModals.manageTagsModal;
        dispatch(stateController.setState({ isProcessing: true }));

        const sortedTags = productTags.map((item) => {
          if (item.id.startsWith('new')) {
            return { name: item.name };
          }
          return item;
        });

        const data: ManageProductTagsData = {
          productMetaId: entityId,
          tags: sortedTags,
        };

        const productTagRelations = await ProductTagsService.manageProductTags(data);
        const mappedTags = productTagRelations.map((item) => item.tag);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            items: prev.items.map((item) => {
              if (item.product_meta.id !== entityId) return item;
              return { ...item, tags: mappedTags };
            }),
          })),
        );

        dispatch(ProductsModalsActions.closeModal(ProductsModalsNameEnum.ManageTags));
        notify.success(<>{t('product_flow.notification.update_product_success')}</>);
      } catch (error) {
        notify.error(<>{t('product_flow.notification.update_product_error')}</>);
        throw error;
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static duplicateProduct(productId: string) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      try {
        stateController.setState({ isProcessing: true });

        const newProduct = await ProductsService.duplicateProduct(productId);
        dispatch(Actions.addProductToList(newProduct));

        notify.success('Successfully duplicated');
      } catch {
        notify.error(<>{t('product_flow.notification.create_product_error')}</>);
      } finally {
        stateController.setState({ isProcessing: true });
      }
    };
  }

  public static addProductToList(product: ProductData) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          items: [product, ...prev.items],
        })),
      );
    };
  }

  public static onProductIsActiveChange(productId: string, isProductActive: boolean) {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            items: prev.items.map((item) => (item.id === productId ? { ...item, is_active: !isProductActive } : item)),
          })),
        );
        await ProductsService.changeIsActive(productId, { is_active: !isProductActive });
        if (isProductActive) {
          notify.success('Product inactivated successfully');
        } else {
          notify.success('Product activated successfully');
        }
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            items: prev.items.map((item) => (item.id === productId ? { ...item, is_active: !isProductActive } : item)),
          })),
        );
      }
    };
  }

  public static removeProductFromList(productId: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          items: prev.items.filter((item) => item.id !== productId),
        })),
      );
    };
  }

  public static setIsLoading(value: boolean) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isLoading: value }));
      return true;
    };
  }

  // ====== Quick search =====================================================================================================

  public static getItemsByKeyword(keyword: string) {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            quickSearch: {
              ...prev.quickSearch,
              isFetching: true,
            },
          })),
        );

        const categories = (await ProductCategoriesService.getCategoriesByKeyword(keyword)).sort();
        const products = (await ProductsService.getProductsByKeyword(keyword)).sort();

        const categoriesWithType: IdNameType[] = categories.map((item) => ({
          ...item,
          type: EntityType.Category,
        }));
        const productsWithType: IdNameType[] = products.map((item) => ({
          ...item,
          type: EntityType.Product,
        }));

        const sortedItems = [...categoriesWithType, ...productsWithType];

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            quickSearch: {
              ...prev.quickSearch,
              items: sortedItems,
            },
          })),
        );
      } catch (err) {
        notify.error('Something went wrong');
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            quickSearch: {
              ...prev.quickSearch,
              isFetching: false,
            },
          })),
        );
      }
    };
  }

  public static clearItemList() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          quickSearch: {
            ...prev.quickSearch,
            items: [],
          },
        })),
      );
    };
  }

  public static openProductSearchPage(value: string) {
    return async (dispatch) => {
      dispatch(ProductSearchActions.onKeywordChange(value, false));
      router.navigate('/product-search');
    };
  }
}

export class Selectors {
  public static isEmptyState(state: AppState) {
    const { products, categories } = state.products;
    if (products.items.length === 0 && categories.items.length === 0) {
      return true;
    }
    return false;
  }
}

export const reducer = stateController.getReducer();
