import { baseAxiosInstance } from 'axios-config';
import { TAKE_ITEMS } from 'constants/take-items';
import { PaginatedResponse, IdName } from 'types/common-types';

export class ProductOptionValuesService {
  public static async getAllUniqueNames(search = '', skip = 0, take = TAKE_ITEMS) {
    const { data } = await baseAxiosInstance.get<PaginatedResponse<IdName[]>>('product-option-values/all-names', {
      params: {
        search,
        skip,
        take,
      },
    });

    return data;
  }
}
