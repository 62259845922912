import { Chip, InputAdornment, OutlinedInput, TextField } from '@mui/material';
import Autocomplete from 'components/ui-new/autocomplete/autocomplete';
import { SearchIcon } from 'icons/search';
import { useState } from 'react';
import Select from '../../../components/ui-new/select/select';

const numbersArray = ['one', 'two', 'three', 'four', 'five'].map((item) => ({ id: item, value: item }));
type Numbers = {
  id: string;
  value: string;
};

export default function MuiTextField() {
  const [number, setNumber] = useState<Numbers>(null);
  const [numbers, setNumbers] = useState<Numbers[]>([]);
  const [numberS, setNumberS] = useState('');

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-start', gap: '10px', marginTop: 10 }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginTop: 10 }}>
        <TextField placeholder="Placeholder" size="medium" />
        <TextField placeholder="Placeholder" size="small" />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginTop: 10 }}>
        <Autocomplete
          disableClearable
          sx={{ width: '210px' }}
          size="medium"
          multiple={false}
          options={numbersArray}
          value={number}
          onChange={(event, newValue: Numbers) => {
            setNumber(newValue);
          }}
          renderInput={(params) => (
            <TextField
              size="medium"
              placeholder="Autocomplete"
              onChange={(e) => setNumber({ id: null, value: e.target.value })}
              {...params}
            />
          )}
          getOptionLabel={(option: Numbers) => option.value}
          placeholder="Placeholder"
        />
        <Autocomplete
          disableClearable
          sx={{ width: '185px' }}
          size="small"
          multiple={false}
          options={numbersArray}
          value={number}
          onChange={(event, newValue: Numbers) => {
            setNumber(newValue);
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              placeholder="Autocomplete"
              onChange={(e) => setNumber({ id: null, value: e.target.value })}
              {...params}
            />
          )}
          getOptionLabel={(option: Numbers) => option.value}
          placeholder="Placeholder"
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginTop: 10 }}>
        <OutlinedInput
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search"
          size="small"
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginTop: 10 }}>
        <Autocomplete
          sx={{ width: '280px' }}
          size="medium"
          multiple
          disableCloseOnSelect
          noOptionsText="No Option"
          value={numbers}
          options={numbersArray}
          onChange={(event, newValue: Numbers[]) => {
            setNumbers(newValue);
          }}
          renderInput={(props) => <TextField size="small" placeholder="Autocomplete" {...props} />}
          renderTags={(tagValue: Numbers[], getTagProps) => {
            return tagValue.map((option, index) => <Chip key={option.id} {...getTagProps({ index })} label={option.value} />);
          }}
          getOptionLabel={(option: Numbers) => option.value}
        />
        <Autocomplete
          sx={{ width: '280px' }}
          size="small"
          multiple
          disableCloseOnSelect
          filterSelectedOptions
          noOptionsText="No Option"
          value={numbers}
          options={numbersArray}
          onChange={(event, newValue: Numbers[]) => {
            setNumbers(newValue);
          }}
          renderInput={(props) => <TextField size="small" placeholder="Autocomplete" {...props} />}
          renderTags={(tagValue: Numbers[], getTagProps) => {
            return tagValue.map((option, index) => <Chip key={option.id} {...getTagProps({ index })} label={option.value} />);
          }}
          getOptionLabel={(option: Numbers) => option.value}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', marginTop: 10 }}>
        <Select
          displayEmpty
          emptyValue=""
          sx={{ width: '280px' }}
          value={numberS}
          size="medium"
          onChange={(e) => {
            setNumberS(e);
          }}
          options={numbersArray}
          placeholder="Select"
        />
        <Select
          displayEmpty
          emptyValue=""
          sx={{ width: '280px' }}
          value={numberS}
          fontSize="small"
          size="small"
          onChange={(e) => {
            setNumberS(e);
          }}
          options={numbersArray}
          placeholder="Select"
        />
      </div>
    </div>
  );
}
