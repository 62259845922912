import { Stack, Typography } from '@mui/material';

import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { FC } from 'react';
import { FailReasonsModel } from 'services/production-task.model';
import s from './failed-information-modal.module.scss';

type StateProps = {
  fail_reasons: FailReasonsModel[];
  fail_comment: string;
};
type Props = StateProps;

const FailedInformationModal: FC<Props> = ({ fail_reasons, fail_comment }) => {
  return (
    <Stack gap="16px">
      <Stack gap="8px">
        {!!fail_reasons.length && (
          <Stack gap="6px">
            <Typography className={s.sub_heading}>The list of reasons:</Typography>
            <div>
              {fail_reasons.map(({ id, en_reason }) => (
                <Stack key={id} className={s.tasks_reopen_wrapper}>
                  <div className={s.bullet_point} />
                  <Stack direction="row" gap={1}>
                    <Typography className={s.tasks_item}>{en_reason}</Typography>
                  </Stack>
                </Stack>
              ))}
            </div>
          </Stack>
        )}

        {fail_comment && (
          <>
            <Stack gap="3px">
              <Typography className={s.sub_heading}>Additional comment:</Typography>
            </Stack>
            <Stack gap="3px">
              <div className={s.additional_comment}>{fail_comment}</div>
            </Stack>
          </>
        )}
        {!fail_comment && !fail_reasons.length && (
          <Stack gap="3px">
            <div className={s.empty_state}>
              The person who failed the task did not specify the reason for the task failure. For more information, please contact
              the responsible person.
            </div>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  fail_reasons: state.task.task.fail_reasons,
  fail_comment: state.task.task.fail_comment,
});

export default connect(mapStateToProps, null)(FailedInformationModal);
