import { baseAxiosInstance } from 'axios-config';
import { TAKE_ITEMS } from 'constants/take-items';
import { IdName } from 'types/common-types';
import {
  CreateProductData,
  Product,
  ProductDataPublishedProducts,
  ProductInfoResponse,
  ProductMeta,
  UpdateProductMeta,
  ProductPreviewInfoResponse,
  ProductWithVersion,
  RelatedProductResponse,
  UpdateProduct,
  IsProductDeleteResponse,
  ProductForProduction,
  BarcodeValidationModel,
  FilterParams,
  ProductsByFilterData,
  ProductMetaFindResponse,
} from './products.model';

export class ProductsService {
  public static async getProducts(params?: {
    product_vendor_id?: string;
    product_type_id?: string;
    product_category_id?: string;
  }) {
    const { data } = await baseAxiosInstance.get<ProductDataPublishedProducts[]>(`/products/all`, {
      params,
    });

    return data;
  }

  public static async getProductDetailedInfoById(productId: string) {
    const { data } = await baseAxiosInstance.get<ProductInfoResponse>(`/products/info/${productId}`);
    return data;
  }

  public static async getRelatedProduct(productId: string) {
    const { data } = await baseAxiosInstance.get<RelatedProductResponse[]>(`/products/${productId}/related-products`);
    return data;
  }

  public static async getProductWithVersion(search = '', skip = 0, take = 20) {
    const { data } = await baseAxiosInstance.get<ProductWithVersion>(`/products/products-with-versions`, {
      params: {
        search,
        skip,
        take,
      },
    });
    return data;
  }

  public static async getProductPreviewDetailedInfoById(productId: string) {
    const { data } = await baseAxiosInstance.get<ProductPreviewInfoResponse>(`/products/view/${productId}`);
    return data;
  }

  public static async getProductById(productId: string) {
    const { data } = await baseAxiosInstance.get<Product>(`/products/${productId}`);
    return data;
  }

  public static async updateProduct(id: string) {
    const { data } = await baseAxiosInstance.put<Product>(`/products/update/${id}`);
    return data;
  }

  public static async changeIsActive(id: string, productData: UpdateProduct) {
    const { data } = await baseAxiosInstance.put<{ message: string }>(`/products/change-is-active/${id}`, productData);
    return data;
  }

  public static async canDeleteProduct(id: string) {
    const { data } = await baseAxiosInstance.get<IsProductDeleteResponse>(`/products/delete/${id}`);
    return data;
  }

  public static async updateProductMeta(id: string, productData: UpdateProductMeta) {
    const { data } = await baseAxiosInstance.put<ProductMeta>(`/product-metas/${id}`, productData);
    return data;
  }

  public static async getProductMeta(search = '', skip = 0, take = TAKE_ITEMS) {
    const { data } = await baseAxiosInstance.get<ProductMetaFindResponse[]>(`/product-metas/find`, {
      params: {
        search,
        skip,
        take,
      },
    });
    return data;
  }

  public static async updateParentCategory(productMetaIds: string[], categoryId: string) {
    const { data } = await baseAxiosInstance.put(`/product-metas/update-parent-category`, {
      productMetaIds,
      product_category_id: categoryId,
    });
    return data;
  }

  public static async createProduct(productData: CreateProductData) {
    const { data } = await baseAxiosInstance.post<Product>('/products/create', productData);
    return data;
  }

  public static async publishProduct(productData: { id: string }) {
    const { data } = await baseAxiosInstance.post(`/products/publish`, productData);
    return data;
  }

  public static async deleteProduct(id: string) {
    const { data } = await baseAxiosInstance.delete<Product>(`/products/delete/${id}`);
    return data;
  }

  public static async resetDraft(id: string) {
    const { data } = await baseAxiosInstance.delete<Product>(`/products/reset-draft/${id}`);
    return data;
  }

  public static async duplicateProduct(productId: string) {
    const { data } = await baseAxiosInstance.post<ProductDataPublishedProducts>(`/products/duplicate`, { id: productId });
    return data;
  }

  public static async updateMainVersion(id: string) {
    const { data } = await baseAxiosInstance.put<{ message: string }>(`/products/change-main-version/${id}`);
    return data;
  }

  public static async getProductsByKeyword(keyword: string) {
    const { data } = await baseAxiosInstance.get<IdName[]>(`/products/search-short-info`, {
      params: {
        search: keyword,
      },
    });
    return data;
  }

  public static async getProductForProduction({
    workflow_template_id,
    product_variant_id,
  }: {
    workflow_template_id?: string;
    product_variant_id?: string;
  }) {
    let query = '';
    if (workflow_template_id) {
      query = `?workflow_template=${workflow_template_id}`;
    }
    if (product_variant_id) {
      query = `?product_variant=${product_variant_id}`;
    }
    const { data } = await baseAxiosInstance.get<ProductForProduction[]>(`/products/get-for-production${query}`);
    return data;
  }

  public static async barcodeUniqueValidation(barcode: string, productId: string) {
    const { data } = await baseAxiosInstance.get<BarcodeValidationModel>(`/products/is-barcode-valid/${productId}`, {
      params: {
        barcode,
      },
    });
    return data;
  }

  public static async getProductsByFilter(body: FilterParams) {
    const { data } = await baseAxiosInstance.get<ProductsByFilterData>(`/products/search`, {
      params: {
        order_by: body.order_by,
        sku: body.sku || undefined,
        skip: body.skip || undefined,
        take: body.take || undefined,
        is_available: body.is_available,
        contains_draft: body.contains_draft,
        order_direction: body.order_direction,
        parameters: body.parameters || undefined,
        product_search_query: body.name || undefined,
        product_name: body.product_name || undefined,
        product_tag_id: body.product_tag_id || undefined,
        publish_date_end: body.publish_date_end || undefined,
        product_vendor_id: body.product_vendor_id || undefined,
        publish_date_start: body.publish_date_start || undefined,
        product_category_id: body.product_category_id || undefined,
        last_update_date_end: body.last_update_date_end || undefined,
        last_update_date_start: body.last_update_date_start || undefined,
      },
    });
    return data;
  }
}
