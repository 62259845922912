import { AppState } from 'redux/store';
import { notify } from 'notifications';
import { StateController } from 'state-controller';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';

const NUMBER_OF_ITEMS_TO_SHOW = 15;

export type ProductionItem = {
  id: string;
  title: string;
  productionKey: string;
};

export type DeleteProductionsModalState = {
  isOpen: boolean;
  isLoading: boolean;
  selectedItems: ProductionItem[];
  externalItems: ProductionItem[];
};

const defaultState: DeleteProductionsModalState = {
  isOpen: false,
  isLoading: false,
  externalItems: [],
  selectedItems: [],
};

const stateController = new StateController<DeleteProductionsModalState>('DELETE_PRODUCTIONS_MODAL', defaultState);

export class DeleteProductionsModalActions {
  public static openModal() {
    return async (dispatch, getState: () => AppState) => {
      const selectedItems = getState().production.productionList.selectedProductions;
      const selectedItemsMapped = selectedItems.map((i) => ({
        id: i.id,
        title: i.title,
        productionKey: i.production_key,
      }));
      const externalItemsMapped = selectedItems
        .filter((yo) => yo.is_external)
        .map((i) => ({
          id: i.id,
          title: i.title,
          productionKey: i.production_key,
        }));

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isOpen: true,
          selectedItems: selectedItemsMapped,
          externalItems: externalItemsMapped,
        })),
      );
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
      setTimeout(() => dispatch(stateController.setState({ ...defaultState })), 100);
    };
  }

  public static onDelete() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const { selectedItems } = getState().production.deleteProductionsModal;
        const { filters } = getState().production;
        const selectedIds = selectedItems.map((i) => i.id);
        const count = selectedItems.length;

        await ProductionWorkflowService.deleteMany(selectedIds);
        await dispatch(
          ProductionFiltersActions.getProductionsByFilter({
            showFetchEffect: false,
            customGroupBy: filters.groupBy,
            resetSkipPreserveTake: true,
          }),
        );

        dispatch(DeleteProductionsModalActions.closeModal());
        notify.success(`${count} ${count === 1 ? 'item' : 'items'} deleted`);
      } catch (err) {
        const { selectedItems } = getState().production.deleteProductionsModal;
        const itemsLeft = selectedItems.length > NUMBER_OF_ITEMS_TO_SHOW ? selectedItems.length - NUMBER_OF_ITEMS_TO_SHOW : 0;

        dispatch(stateController.setState({ isLoading: false }));
        notify.error(
          <div style={{ maxWidth: 310 }}>
            <p style={{ whiteSpace: 'nowrap' }}>Following production items weren&apos;t deleted:</p>
            <div style={{ paddingLeft: '10px' }}>
              {selectedItems.slice(0, NUMBER_OF_ITEMS_TO_SHOW).map((i) => (
                <div key={i.id} style={{ display: 'flex', gap: '7px', alignItems: 'center' }}>
                  <div style={{ minHeight: 3, minWidth: 3, borderRadius: '50%', backgroundColor: 'black' }} />
                  <div style={{ display: 'flex', gap: '4px', maxWidth: '95%' }}>
                    <b
                      style={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {i.title}
                    </b>
                    <p style={{ flexShrink: 0 }}>({i.productionKey})</p>
                  </div>
                </div>
              ))}
              {Boolean(itemsLeft) && (
                <p>
                  ...and other {itemsLeft} {itemsLeft === 1 ? 'production' : 'productions'}
                </p>
              )}
            </div>
          </div>,
        );
        throw err;
      }
    };
  }
}

export const deleteProductionsModalReducer = stateController.getReducer();
