import { ProductionIssueEnum } from 'services/production-workflow.model';

export type IdName = {
  id: string;
  name: string;
};

export type IdNameIsActive = IdName & {
  is_active?: boolean;
};
export type IdNameType = {
  id: string;
  name: string;
  type: string;
};

export type IdValue = {
  id: string;
  value: string;
};

export type RelatedTask = {
  id: string;
  name: string;
  position_x: number;
  position_y: number;
};

export type IdVersion = {
  id: string;
  version: number;
  is_active: boolean;
};

export type IdNameOrder = { id: string; name: string; order: number };

export type ValueText = {
  value: string;
  text: string;
};

export type PaginatedResponse<T> = {
  data: T;
  meta: PaginationData;
};
export type PaginationData = {
  currentPage: number;
  lastPage: number;
  next: number | null;
  prev?: number | null;
  total: number;
  perPage: number;
};

export type MetaResponse = {
  currentPage: number;
  lastPage: number;
  next: number | null;
  perPage: number;
  prev: number | null;
  total: number;
};

export type SelectOption = {
  value: any;
  label: string;
};

export type FileItem = {
  id: string;
  name: string;
  link: string;
  isUploading?: boolean;
  progress?: number;
};

export type MinMax = {
  min: number | null;
  max: number | null;
};

export type SortBy<T> = {
  sortBy: T;
  order: SortOrderOption;
};

export enum SortOrderOption {
  Ascending = 'asc',
  Descending = 'desc',
}

export type IssueKeywordEnum = {
  [key in ProductionIssueEnum]: string;
};

export const issueKeywords: IssueKeywordEnum = {
  [ProductionIssueEnum.UndefinedProduct]: 'Unknown product',
  [ProductionIssueEnum.ProductionDeadlineExpired]: 'The deadline has been reached already',
  [ProductionIssueEnum.IssuesInNestedComponents]: 'Nested production component has an issue',
  [ProductionIssueEnum.TaskTimeLimitExceeded]: 'The task tracker time has exceeded the task time limit',
  [ProductionIssueEnum.TasksRequiringManualAssignment]: 'Manual assignment for a task required',
};
