import { FC } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { CheckIcon } from 'icons/check';
import { EnvCookies } from 'env-cookies';
import MenuItem from '@mui/material/MenuItem';
import Select from 'components/ui-new/select/select';
import { Actions as AuthActions } from 'redux/auth.controller';
import { Tenant, TenantUserStatus } from 'services/tenant.model';
import { CompanyLogo } from 'components/company-logo/company-logo';
import s from './tenants.module.scss';

type OwnProps = {
  isOpen: boolean;
  onClose?: () => void;
};
type StateProps = {
  tenants: Tenant[];
};
type DispatchProps = {
  onChange: (id: string) => void;
};
type Props = OwnProps & StateProps & DispatchProps;

const Tenants: FC<Props> = (props) => {
  const { isOpen, tenants, onChange, onClose } = props;
  const activeTenants = tenants.filter((tenant) => tenant.user_status === TenantUserStatus.Active);
  const tenantId = EnvCookies.get('tenant');

  const selectedCompany = tenants.find((item) => item.id === tenantId);
  if (activeTenants.length === 1) {
    return (
      <div className={s.option}>
        <CompanyLogo className={s.logo} imgSrc={selectedCompany?.logo} companyName={selectedCompany?.name} size="S" />
        {isOpen && <div className={s.company_name}>{selectedCompany?.company_name}</div>}
      </div>
    );
  }

  return (
    <div className={s.container}>
      <Select
        size="small"
        emptyValue=""
        className={s.select}
        hideMenuIcon={!isOpen}
        value={selectedCompany?.id}
        minMenuWidth={isOpen ? null : 184}
        MenuProps={{ classes: { paper: s.menu_container } }}
        renderValue={() => (
          <div className={s.value}>
            <CompanyLogo className={s.logo} imgSrc={selectedCompany?.logo} companyName={selectedCompany?.name} size="S" />
            <div className={s.company_name}>{selectedCompany?.company_name}</div>
          </div>
        )}
        renderOption={(option) => (
          <MenuItem className={s.option} value={option.value} key={option.value}>
            <CompanyLogo className={s.logo} imgSrc={option?.logo} companyName={option?.text} size="S" />
            <div className={s.company_name}>{option?.text}</div>
            {option.value === tenantId && (
              <div className={s.check_icon_container}>
                <CheckIcon className={s.check_icon} stroke="#0851FE" />
              </div>
            )}
          </MenuItem>
        )}
        onClose={onClose}
        onChange={(value) => onChange(value)}
        options={activeTenants.map((i) => ({ value: i.id, text: i.company_name, logo: i.logo }))}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  tenants: state.auth.userTenants,
});
const mapDispatchToProps: DispatchProps = {
  onChange: AuthActions.changeCurrentUserTenant,
};
export default connect(mapStateToProps, mapDispatchToProps)(Tenants);
