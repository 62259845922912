import { ENV, EnvCookies, Envs } from 'env-cookies';
import { KJUR } from 'jsrsasign';
import store from 'redux/store';

const SHARED_CHAT_SECRET = 'MiwxOTgsMTMwLDM0LDI1MCwxNzUsMjEyLDU3LDc4LDIzMSwyMzAsNjQsMzQsMjIyLDE0MCw4MA==';

export const chatWidget: any = document.querySelector('chat-widget');

export const useChatWidget = () => {
  const initChat = () => {
    setTimeout(() => {
      const { user, userTenants } = store.getState().auth;
      if (!user) return initChat();

      // For dev purposes
      // console.log(Object.getPrototypeOf(chatWidget));
      if (EnvCookies.get('auth')) {
        const { email, first_name, last_name } = user;
        const userCompanyName = userTenants?.find((item) => item.id === EnvCookies.get('tenant')).name;
        const companyNamePrefix = `${userCompanyName || 'Unknown'} | `;
        const envPrefixes = {
          development: '[dev] | ',
          local: '[local] | ',
          stage: '[stage] | ',
          production: '',
        } as { [key in Envs]: string };

        const header = {
          alg: 'HS256',
          typ: 'JWT',
        };
        const payload = {
          email,
          displayName: `${envPrefixes[ENV]}${companyNamePrefix}${first_name} ${last_name}`,
          iat: Date.now() / 1000,
          exp: Date.now() / 1000 + 60 * 5,
        };
        const token = KJUR.jws.JWS.sign('HS256', JSON.stringify(header), JSON.stringify(payload), SHARED_CHAT_SECRET);

        chatWidget.loginWithToken(token);
        chatWidget.show();

        const styleId = 'custom-chat-widget-style-init';
        const existingStyle = chatWidget.shadowRoot.querySelector(`#${styleId}`);
        const initStyles = `
          .chat-widget-wrapper {
            transition: bottom 0.6s, right 0.6s;
            bottom: 20px !important;
            right: 20px !important;
          }
          .icons {
            width: 40px !important;
            height: 40px !important;
          }
          .chat-icon-container {
            width: 40px !important;
            height: 40px !important;
          }
          .chat-icon-button {
            width: 40px !important;
            height: 40px !important;
          }
          .chat-icon {
            --regular-size: 22px !important;
            --big-size: 26px !important;
          }
        `;

        if (existingStyle) {
          existingStyle.innerHTML = initStyles;
        } else {
          const style = document.createElement('style');
          style.id = styleId;
          style.innerHTML = initStyles;
          chatWidget.shadowRoot.appendChild(style);
        }
      }
      return undefined;
    }, 1000);
  };

  const hide = () => {
    const interval = setInterval(() => {
      if (chatWidget) {
        chatWidget.style.display = 'none';
        clearInterval(interval);
      }
    }, 100);
  };

  const show = () => {
    const interval = setInterval(() => {
      if (chatWidget) {
        chatWidget.style.display = '';
        clearInterval(interval);
      }
    }, 100);
  };

  const setPosition = ({ bottom, right }) => {
    let widget = document.querySelector('chat-widget');
    let attempts = 0;

    while (!widget && attempts < 20) {
      widget = document.querySelector('chat-widget');
      attempts += 1;
    }

    const styleId = 'custom-chat-widget-style-position';
    const existingStyle = widget.shadowRoot.querySelector(`#${styleId}`);
    const styles = `
      .chat-widget-wrapper {
        bottom: ${bottom} !important;
        right: ${right} !important;
      }
    `;

    if (existingStyle) {
      existingStyle.innerHTML = styles;
    } else {
      const style = document.createElement('style');
      style.id = styleId;
      style.innerHTML = styles;
      widget.shadowRoot.appendChild(style);
    }
  };

  const setDefaultPosition = () => {
    let widget = document.querySelector('chat-widget');
    let attempts = 0;

    while (!widget && attempts < 20) {
      widget = document.querySelector('chat-widget');
      attempts += 1;
    }

    const styleId = 'custom-chat-widget-style-position';
    const existingStyle = widget.shadowRoot.querySelector(`#${styleId}`);
    const styles = `
      .chat-widget-wrapper {
        bottom: 20px !important;
        right: 20px !important;
      }
    `;

    if (existingStyle) {
      existingStyle.innerHTML = styles;
    } else {
      const style = document.createElement('style');
      style.id = styleId;
      style.innerHTML = styles;
      widget.shadowRoot.appendChild(style);
    }
  };

  const setTransform = (transformValue: string) => {
    const widget = document.querySelector('chat-widget');
    const styleId = 'custom-chat-widget-style-transform';
    const existingStyle = widget.shadowRoot.querySelector(`#${styleId}`);
    const styles = `
      .chat-widget-wrapper { 
        transform: ${transformValue} !important;
      }
    `;

    if (existingStyle) {
      existingStyle.innerHTML = styles;
    } else {
      const style = document.createElement('style');
      style.id = styleId;
      style.innerHTML = styles;
      widget.shadowRoot.appendChild(style);
    }
  };

  chatWidget.onload = () => {
    chatWidget.hide();
    initChat();
  };

  return {
    hide: () => hide(),
    show: () => show(),
    setDefaultPosition: () => setDefaultPosition(),
    setTransform: (transformValue) => setTransform(transformValue),
    setPosition: ({ bottom, right }) => setPosition({ bottom, right }),
  };
};
