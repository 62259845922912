import { ActiveTickIcon } from 'icons/active-tick';
import FireMinusIcon from 'icons/fire-minus';
import { WarningColorIcon } from 'icons/warning-color';
import { MODALS } from 'modules/root-modals/modals';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { AppState, GetStateFunction } from 'redux/store';
import { ProductVersion } from 'services/products.model';
import { ProductsService } from 'services/products.service';
import { StateController } from 'state-controller';
import { notify } from 'notifications';
import { Actions as ProductPreviewController } from './product-preview.controller';
import { Actions as ProductRootController } from './product-root.controller';
import { Actions as ProductConfigurationController } from './product-configurations.controller';
import s from '../product.module.scss';

export type ProductState = {
  isProcessing: boolean;
  versions: ProductVersion[];
  deleteModal: {
    isOpen: boolean;
  };
  makeMainVersionModal: {
    isOpen: boolean;
  };
  unableToDeleteModal: {
    isOpen: boolean;
  };
  changeIsActiveModal: {
    isOpen: boolean;
  };
};

const defaultState: ProductState = {
  isProcessing: false,
  versions: [],
  deleteModal: {
    isOpen: false,
  },
  makeMainVersionModal: {
    isOpen: false,
  },
  unableToDeleteModal: {
    isOpen: false,
  },
  changeIsActiveModal: {
    isOpen: false,
  },
};

const stateController = new StateController<ProductState>('PRODUCT_VERSION', defaultState);

export class Actions {
  public static init(versions: ProductVersion[]) {
    return (dispatch) => {
      dispatch(stateController.setState({ versions }));
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static setIsProcessing() {
    return (dispatch) => {
      dispatch(stateController.setState({ isProcessing: true }));
    };
  }

  public static makeMainVersion(id: string) {
    return async (dispatch) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));
        await ProductsService.updateMainVersion(id);
        dispatch(
          stateController.setState((prev) => ({
            versions: prev.versions.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  is_main_version: true,
                };
              }
              return { ...item, is_main_version: false };
            }),
          })),
        );
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
        dispatch(Actions.closeModal('makeMainVersionModal'));
      }
    };
  }

  public static deleteDraft(id: string) {
    return async (dispatch) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));
        await ProductsService.resetDraft(id);
        dispatch(
          stateController.setState((prev) => ({
            versions: prev.versions.filter((item) => item.id !== id),
          })),
        );
        notify.success('Successfully deleted');
        dispatch(ProductRootController.initProduct(id));
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
        dispatch(Actions.closeModal('deleteModal'));
      }
    };
  }

  public static changeIsActive(id: string, is_active: boolean, version: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { name } = getState().product.product_root.product_meta;
      try {
        dispatch(stateController.setState({ isProcessing: true }));
        await ProductsService.changeIsActive(id, { is_active });
        dispatch(
          stateController.setState((prev) => ({
            versions: prev.versions.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  is_active,
                };
              }
              return item;
            }),
          })),
        );
        if (is_active) {
          notify.success(`${name} ${version} activated`);
        } else {
          notify.success(`${name} ${version} deactivated`);
        }
        dispatch(ProductPreviewController.updateIsActiveProduct());
        dispatch(Actions.closeModal('changeIsActiveModal'));
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static openMakeMainVersionConfirmationModal(id: string, name: string) {
    return (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Зробити головною версію?',
            text: (
              <>
                Ви впевнені, що хочете зробити головною версію <strong>{name}</strong>?
              </>
            ),
            icon: <WarningColorIcon />,
            backgroundColor: '#FCE8C0',
            withCloseButton: false,
            actionText: 'Confirm',
            action: () => dispatch(Actions.makeMainVersion(id)),
          },
        }),
      );
    };
  }

  public static openDeleteDraftConfirmationModal(id: string) {
    return (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Delete draft?',
            text: (
              <>
                All changes made in the draft will be deleted. A new draft can be created based on the latest version of the
                product.
              </>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: 'Delete',
            action: () => dispatch(Actions.deleteDraft(id)),
          },
        }),
      );
    };
  }

  public static openUnableToDeleteConfirmationModal() {
    return (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Видалення неможливе!',
            text: 'Версія використовується в інших продуктах',
            icon: <WarningColorIcon />,
            backgroundColor: '#FCE8C0',
            withCloseButton: true,
            actionText: null,
            action: null,
            cancelButton: false,
            onClose: () => dispatch(Actions.closeModal('unableToDeleteModal')),
          },
        }),
      );
    };
  }

  public static openChangeIsActiveConfirmationModal(id: string, version: string, is_active: boolean) {
    return (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { name } = getState().product.product_root.product_meta;
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: is_active ? 'Activate product version?' : 'Inactivate product version?',
            text: is_active ? (
              <div className={s.activate_product_container}>
                <span className={s.text}>
                  Opening access to{' '}
                  <strong>
                    {name} {version}
                  </strong>{' '}
                  will allow this version to be selected for production or used in{' '}
                  <span
                    className={s.related_product}
                    onClick={() => {
                      dispatch(ProductConfigurationController.openRelationProductModal(id, name, version.replace(/\D/g, '')));
                    }}
                  >
                    related products.
                  </span>
                </span>
                <span className={s.text}>
                  Are you sure you want to activate <strong>{version}</strong> of the product?
                </span>
              </div>
            ) : (
              <div className={s.activate_product_container}>
                <span className={s.text}>
                  Closing access to{' '}
                  <strong>
                    {name} {version}
                  </strong>{' '}
                  will prevent this version from being selected for production or used in{' '}
                  <span
                    className={s.related_product}
                    onClick={() => {
                      dispatch(ProductConfigurationController.openRelationProductModal(id, name, version.replace(/\D/g, '')));
                    }}
                  >
                    related products.
                  </span>
                </span>
                <span className={s.text}>
                  Are you sure you want to inactivate <strong>{version}</strong> of the product?
                </span>
              </div>
            ),
            icon: is_active ? <ActiveTickIcon /> : <FireMinusIcon />,
            backgroundColor: is_active ? '#BCF4DE' : '#FFCECE',
            withCloseButton: false,
            actionText: is_active ? 'Activate' : 'Inactivate',
            actionButtonColor: is_active ? 'primary' : 'error',
            action: () => dispatch(Actions.changeIsActive(id, is_active, version)),
          },
        }),
      );
    };
  }

  public static updateVersionsState(id: string, actionType: 'set_main' | 'delete') {
    return (dispatch, getState: GetStateFunction) => {
      const { versions } = getState().product.product_version;
      let res;
      if (actionType === 'delete') {
        res = versions.filter((item) => item.id !== id);
      }
      if (actionType === 'set_main') {
        res = versions.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              is_main_version: true,
            };
          }
          return {
            ...item,
            is_main_version: false,
          };
        });
      }
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          versions: res,
        })),
      );
    };
  }

  public static closeModal(modalName: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          [modalName]: defaultState[modalName],
        })),
      );
    };
  }
}

export class Selectors {
  public static selectedVersion(state: AppState) {
    const { versions } = state.product.product_version;
    const draftVersion = versions.find((i) => i.is_published === false);
    return draftVersion || versions[0];
  }

  public static getIndexOfLastVersion(state: AppState) {
    const { versions } = state.product.product_version;
    const draftVersionIndex = versions.findIndex((i) => i.is_published === false);
    if (draftVersionIndex !== -1) {
      return draftVersionIndex;
    }
    return 0;
  }
}

export const reducer = stateController.getReducer();
