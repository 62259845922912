import { FC } from 'react';
import { connect } from 'react-redux';
import { CurrentUser } from 'services/user.model';
import { TextEllipsis } from 'components/text-ellipsis/text-ellipsis';
import { AvatarCircle } from 'components/ui-new/avatar-circle/avatar-circle';
import { Actions as AuthActions } from '../../../../../../redux/auth.controller';
import s from './user-menu.module.scss';

type DispatchProps = {
  onLogout: () => void;
};
type OwnProps = {
  user: CurrentUser;
  isMenuOpen: boolean;
  onBackdropClick: (isOpen: boolean) => void;
};
type Props = DispatchProps & OwnProps;

const UserMenu: FC<Props> = ({ user, isMenuOpen, onLogout, onBackdropClick }) => {
  const userName = `${user?.first_name || ''} ${user?.last_name || ''}`;

  return (
    <>
      <div className={s.backdrop} data-is-menu-open={isMenuOpen} onClick={() => onBackdropClick(false)} />

      <div className={s.menu} data-is-menu-open={isMenuOpen}>
        <div className={s.user}>
          <AvatarCircle
            withHover={false}
            src={user?.avatar_image_url}
            lastName={user?.last_name || ''}
            style={{ width: 42, height: 42 }}
            isEmpty={!!user?.avatar_image_url}
            firstName={user?.first_name || ''}
          />
          <div className={s.info}>
            <TextEllipsis tooltipTitle={userName} className={s.bold}>
              {userName}
            </TextEllipsis>
            <TextEllipsis tooltipTitle={user?.email} className={s.email}>
              {user?.email}
            </TextEllipsis>
          </div>
        </div>
        <div onClick={onLogout} className={s.item}>
          Log out
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps: DispatchProps = {
  onLogout: AuthActions.logout,
};

export default connect(null, mapDispatchToProps)(UserMenu);
