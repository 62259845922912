import { FC, ReactNode } from 'react';
import Modal from 'components/ui-new/modal/modal';
import ModalSvgWrapper from 'icons/modal-svg-wrapper/svg-wrapper';
import ModalHeader from 'components/modal-header/modal-header';
import { CircularProgress, FormControl, TextField, Typography } from '@mui/material';
import ModalBody from 'components/modal-body/modal-body';
import ModalFooter from 'components/modal-footer/modal-footer';
import Autocomplete from 'components/ui-new/autocomplete/autocomplete';
import Subtitle from 'components/subtitle/subtitle';
import Label from 'components/label/label';
import { FolderIcon } from 'icons/folder';
import { IdName } from 'types/common-types';
import { SearchIcon } from 'icons/search';
import Button from 'components/button/button';
import s from './move-modal.module.scss';

type OwnProps = {
  isOpen: boolean;
  label: string;
  subTitle?: string;
  placeholder?: string;
  moveButtonText?: string;
  options: Array<IdName>;
  isLoadingOptions?: boolean;
  value: IdName;
  dontClose?: boolean;
  isProcessing?: boolean;
  isMoveButtonDisabled?: boolean;
  warning?: ReactNode | undefined;
  handleMove: () => void;
  onChangeValue: (value: IdName) => void;
  closeModal: (modalName?: string) => void;
};

const MoveModal: FC<OwnProps> = ({
  isOpen,
  label,
  warning,
  options,
  dontClose,
  placeholder,
  subTitle,
  isProcessing,
  isMoveButtonDisabled,
  moveButtonText = 'Move to',
  isLoadingOptions,
  value,
  closeModal,
  handleMove,
  onChangeValue,
}) => {
  const onCloseModal = () => closeModal('moveModal');
  return (
    <Modal open={isOpen} onClose={onCloseModal} PaperProps={{ className: s.modal }}>
      <ModalHeader withCloseButton={false} witohutShadow size="newMedium" onClose={onCloseModal} className={s.modal_header}>
        <div className={s.header}>
          <Subtitle
            text="Move to"
            fontSize="large"
            icon={<ModalSvgWrapper containerSize={38} iconSize={20} backgroundColor="#DBE9FF" SvgIcon={FolderIcon} />}
          />
          <Typography fontWeight="400" fontSize={14} lineHeight="21px">
            {subTitle ?? `Select a ${label} from the list`}
          </Typography>
        </div>
      </ModalHeader>
      <ModalBody size="newMedium" className={s.body}>
        {warning && warning}
        <FormControl fullWidth>
          <Label>{label}</Label>
          <Autocomplete
            disableClearable
            loading={isLoadingOptions}
            size="small"
            multiple={false}
            fullWidth
            isHighlightText
            selectedItem={value}
            options={options}
            value={value}
            onChange={(e, newValue: IdName) => {
              onChangeValue(newValue);
            }}
            noOptionsText={
              <div className={s.no_option}>
                <SearchIcon height="16px" width="16px" />
                <span className={s.no_option_title}>No results</span>
              </div>
            }
            renderInput={(params) => (
              <TextField
                size="medium"
                placeholder={placeholder ?? `Select a ${label}`}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoadingOptions ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                {...params}
              />
            )}
            getOptionLabel={(option: IdName) => option.name}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter size="medium" className={s.footer}>
        <Button variant="contained" color="secondary" size="XL" fullWidth onClick={onCloseModal}>
          Cancel
        </Button>
        <Button
          size="XL"
          color="primary"
          variant="contained"
          loading={isProcessing}
          disabled={isMoveButtonDisabled}
          onClick={() => {
            handleMove();

            if (!dontClose) onCloseModal();
          }}
        >
          {moveButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MoveModal;
