export type ManageSelectOrDeselectAllProductionsArgs = {
  resetAll?: boolean;
};

export type LogItemTypes = MoveItemLogTypes | AddedNewLogItem;

export type MoveItemLogTypes = ReplacedLogItem | MovedOutLogItem | MovedToEmptySlotLogItem | MovedToAdditionalLogItem;

export type AddedNewLogItem = {
  attached_to: string;
  production_workflow_item_id_to_attach?: string;
  components_to_add: { product_variant_id: string }[];
  create_plain?: boolean;
};

export type ReplacedLogItem = {
  attached_to: string;
  detached_from: string;
  detached_from_slot: string;
  production_workflow_id: string;
  production_workflow_id_to_replace: string;
  is_moved_from_additional_components: boolean;
  production_workflow_item_id_to_attach: string;
};

export type MovedOutLogItem = {
  attached_to: string;
  detached_from: string;
  detached_from_slot: string;
  is_moved_out_of_root: boolean;
  production_workflow_id: string;
  is_moved_from_additional_components: boolean;
};

export type MovedToEmptySlotLogItem = {
  attached_to: string;
  detached_from: string;
  detached_from_slot: string;
  production_workflow_id: string;
  is_moved_from_additional_components: boolean;
  production_workflow_item_id_to_attach: string;
};

export type MovedToAdditionalLogItem = {
  attached_to: string;
  detached_from?: string;
  detached_from_slot: string;
  production_workflow_id: string;
  is_moved_to_additional_components: boolean;
  is_moved_from_additional_components: boolean;
};

export enum LogType {
  Moved = 'move',
  AddedNew = 'addNew',
}
