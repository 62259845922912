import s from './vertical-shift-animation.module.scss';

type OwnProps = {
  delay?: number;
  isVisible: boolean;
  fadeEffect?: boolean;
  animationTime?: number;
  children: React.ReactNode;
  componentClassName?: string;
  maxHeightWhenIsHiden?: number;
  maxHeightWhenIsShown?: number;
};

const VerticalShiftAnimation = ({
  children,
  isVisible,
  delay = 0,
  fadeEffect = true,
  componentClassName,
  animationTime = 0.3,
  maxHeightWhenIsHiden = 0,
  maxHeightWhenIsShown = 40,
}: OwnProps) => {
  return (
    <div
      className={`${s.wrapper} ${componentClassName}`}
      style={{
        pointerEvents: isVisible ? 'inherit' : 'none',
        opacity: !isVisible && fadeEffect ? 0 : 1,
        transition: `${animationTime}s ease-in-out ${delay}s`,
        maxHeight: isVisible ? maxHeightWhenIsShown : maxHeightWhenIsHiden,
      }}
    >
      {children}
    </div>
  );
};

export default VerticalShiftAnimation;
